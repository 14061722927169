import { NavLink } from "react-router-dom"; 
import { Text } from "react-native";
import React, { useState, useContext } from "react";
import { AppContext } from "../WebContextProvider.js"; 
import Section from "./Section.jsx";

export const mainTextColor = {
    color: "#9B9EA3",
  };

function underlineText(e) {
    e.target.style.textDecoration = 'none'; 
    e.target.style.textDecorationColor = 'black'; 
  }

function removeUnderline(e) {
    e.target.style.textDecoration = 'none'
  }

function ExploreSection() {
    const {setVideo, setEmbedID} = useContext(AppContext);  
    let initialSubheaderFontSize;
    if (window.innerWidth > 568) {
    initialSubheaderFontSize = 37;
    } else {
    initialSubheaderFontSize = 31;
    }

    const [subheaderFontSize, setSubheaderFontSize] = useState(
    initialSubheaderFontSize
    );

    function updateFontSize() {
    if (window.innerWidth > 568) {
        setSubheaderFontSize(37);
    } else {
        setSubheaderFontSize(31);
    }
    }
    return (
        <div className="container-fluid py-4">
            <div class="card-deck-wraper">
                <div class="d-flex flex-type justify-content-center">
                    <div className="sectionGrid">
                        <Section
                            to="/copilot"
                            onClickTutorial={() => {
                                setVideo('Bond Intelligence Intro'); 
                                setEmbedID('NJlUiWPknb4'); 
                            }}
                            icon="chat"
                            header="Copilot"
                        />
                        <Section
                            to="/rel-val"
                            onClickTutorial={() => {
                                setVideo('Relative Value'); 
                                setEmbedID('nUKbl5FPXXU'); 
                            }}
                            icon="scatter_plot"
                            header="RelVal"
                        />
                        <Section
                            to="/tearsheets"
                            onClickTutorial={() => {
                                setVideo('Tearsheets'); 
                                setEmbedID('-smCVEpmT9g');
                            }}
                            icon="summarize"
                            header="Sheets"
                        />
                        <Section
                            to="/models"
                            onClickTutorial={() => {
                                setVideo('Models'); 
                                setEmbedID('Qmi6f8pIfH4'); 
                            }}
                            icon="model_training"
                            header="Models"
                        />
                        <Section
                            to="/screeners"
                            onClickTutorial={() => {
                                setVideo('Bond Intelligence Intro'); 
                                setEmbedID('NJlUiWPknb4'); 
                            }}
                            icon="query_stats"
                            header="Screeners"
                        />
                        <Section
                            to="/insights"
                            onClickTutorial={() => {
                                setVideo('Bond Intelligence Intro'); 
                                setEmbedID('NJlUiWPknb4'); 
                            }}
                            icon="insights"
                            header="Insights"
                        />
                        <Section
                            to="/trade"
                            onClickTutorial={() => {
                                setVideo('Etrade'); 
                                setEmbedID('ztwltLjmOsk'); 
                            }}
                            icon="swap_horiz"
                            header="Trades"
                        />
                        <Section
                            to="/datadictionary"
                            onClickTutorial={() => {
                                setVideo('Bond Intelligence Intro'); 
                                setEmbedID('NJlUiWPknb4'); 
                            }}
                            icon="menu_book"
                            header="Data Dictionary"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExploreSection; 