import React, { useState, useEffect } from "react";
import "../../components/dictionaryss.css";
import { NavLink } from "react-router-dom";
import {
  forwardRef,
  AnchorHTMLAttributes,
  HTMLAttributes,
  HTMLProps,
} from "react";
import { Text } from "react-native";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import JSONDATA from "./Sidebar.json";
import TESTTABLE from "./DataDictionaryJSONs/DataDictionary_Price Prediction Corp.json";
import NAV from "react-bootstrap/Nav";

import { createData, updateTable, sieve } from "./SharedDataDictionary";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(28, 33, 49)",
    color: "rgba(147, 152, 160)",
    fontSize: 24,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    color: "rgba(147, 152, 160)",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "rgba(32, 40, 60)",

  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },

  "&:hover": {
    backgroundColor: "rgba(83, 85, 91)",
  },
}));

function PricePredictionCorp() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerms, setSearchTerms] = useState("");
  const [descript, setDescript] = useState(false);
  const [type, setType] = useState("All");
  const [selected, setSelection] = useState("All");
  const rows = updateTable(TESTTABLE);
  const filteredRows = sieve(rows, descript, type, searchTerms);

  return (
    <>
      <div align="center" width="100%" height="80px">
        <Text
          backgroundColor="#131722"
          style={{
            lineHeight: "80px",
            fontSize: 45,
            color: "white",
            marginTop: 10,
          }}
        >
          Data Dictionary
        </Text>
      </div>
      <div style={{ padding: 15 }}>
        <div class="xyz">
          <div id="mySidebar" class="sidebar" width="250px">
            <div className="ModelTitle">Select a Model</div>

            <select className="DropdownBox">
              <option className="DropdownText"> Fixed Income</option>
              <option className="DropdownText"> Muni Price Prediction</option>
              <option className="DropdownText"> Corperate Forecast</option>
              <option className="DropdownText"> Municipal Forecast</option>
            </select>
            <div className="SeperationBar" />
            <div className="ExploreTitle">Explores</div>

            <div>
              <input
                className="SearchBox"
                placeholder="Search Model"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
              ></input>
              {JSONDATA.filter((val) => {
                if (searchTerm == "") {
                  return val;
                } else if (
                  val.Model.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                  return val;
                }
              }).map((val, key) => {
                return (
                  <NAV className="flex-column">
                    <NavLink exact to={val.URL} class="hellohello" key={key}>
                      {val.Model}
                    </NavLink>
                  </NAV>
                );
              })}
            </div>
          </div>
          <div className="Embed">
            <div class="background">
              <div class="container-horiz">
                <div class="FixedIncomeTitle">Fixed Income</div>
                <div class="ExploreButton">Explore</div>
                <div class="ExploreButton">View Options</div>
              </div>

              <div class="FieldSelectTitle">
                Select a field for more information.
              </div>
              <input
                className="FilterBar"
                placeholder="Filter fields in this Explore"
                onChange={(event) => {
                  setSearchTerms(event.target.value);
                }}
              ></input>
              <div class="container-horiz">
                <div class="container-vertical">
                  <div class="FilterTitle">Filter</div>
                  <div>
                    {" "}
                    <button
                      class={`FilterButton ${
                        descript ? "ActiveButton" : "FilterButton"
                      }`}
                      onClick={() => {
                        if (descript) {
                          setDescript(false);
                        } else {
                          setDescript(true);
                        }
                      }}
                    >
                      Has Description
                    </button>{" "}
                  </div>
                  {/* <FilterOpt name="Has Description" property="Description"> </FilterOpt> */}
                  <div>
                    {/* <Filter label="Has Description" property="Description"/> */}
                  </div>
                  {/* <div> <button onClick={() => null} class="FilterButton">Has Tags</button> </div>
                <div> <button onClick={() => null} class="FilterButton">Has Comments</button> </div> */}
                </div>
                {/* <div class="container-vertical">
                <div class="FilterTitle">Fields</div>
                <div class="container-horiz">
                  <div class="FilterButton">Dimension</div>
                  <div class="FilterButton">Measure</div>
                </div>
              </div> */}
                {/* <div class="container-vertical">
                <div class="FilterTitle">Has Tags</div>
                <div class="container-horiz">
                  <div class="FilterButton">Yes</div>
                  <div class="FilterButton">No</div>
                </div>
              </div> */}
                {/* <div class="container-vertical">
                <div class="FilterTitle">Has Comments</div>
                <div class="container-horiz">
                  <div class="FilterButton">Yes</div>
                  <div class="FilterButton">No</div>
                </div>
              </div> */}
                <div class="container-vertical">
                  <div class="FilterTitle">Type</div>
                  <div class="container-horiz">
                    <div>
                      {" "}
                      <button
                        class={`FilterButton ${
                          selected ? "ActiveButton" : "FilterButton"
                        }`}
                        onClick={() => {
                          if (type == "Number") {
                            setType("All");
                            setSelection(false);
                          } else {
                            setType("Number");
                            setSelection(true);
                          }
                        }}
                      >
                        Number
                      </button>{" "}
                    </div>
                    <div>
                      {" "}
                      <button
                        class={`FilterButton ${
                          selected ? "ActiveButton" : "FilterButton"
                        }`}
                        onClick={() => {
                          if (type == "String") {
                            setType("All");
                            setSelection(false);
                          } else {
                            setType("String");
                            setSelection(true);
                          }
                        }}
                      >
                        String
                      </button>{" "}
                    </div>
                    <div>
                      {" "}
                      <button
                        class={`FilterButton ${
                          selected ? "ActiveButton" : "FilterButton"
                        }`}
                        onClick={() => {
                          if (type == "Date") {
                            setType("All");
                            setSelection(false);
                          } else {
                            setType("Date");
                            setSelection(true);
                          }
                        }}
                      >
                        Date
                      </button>{" "}
                    </div>
                    <div>
                      {" "}
                      <button
                        class={`FilterButton ${
                          selected ? "ActiveButton" : "FilterButton"
                        }`}
                        onClick={() => {
                          if (type == "Yesno") {
                            setType("All");
                            setSelection(false);
                          } else {
                            setType("Yesno");
                            setSelection(true);
                          }
                        }}
                      >
                        Yes/No
                      </button>{" "}
                    </div>
                    <div>
                      {" "}
                      <button
                        class={`FilterButton ${
                          selected ? "ActiveButton" : "FilterButton"
                        }`}
                        onClick={() => {
                          if (type == "Average") {
                            setType("All");
                            setSelection(false);
                          } else {
                            setType("Average");
                            setSelection(true);
                          }
                        }}
                      >
                        Average
                      </button>{" "}
                    </div>
                    <div>
                      {" "}
                      <button
                        class={`FilterButton ${
                          selected ? "ActiveButton" : "FilterButton"
                        }`}
                        onClick={() => {
                          if (type == "Count") {
                            setType("All");
                            setSelection(false);
                          } else {
                            setType("Count");
                            setSelection(true);
                          }
                        }}
                      >
                        Count
                      </button>{" "}
                    </div>
                  </div>
                  <div></div>
                  <div class="container-horiz">
                    <div>
                      {" "}
                      <button
                        class={"FilterButton"}
                        onClick={() => {
                          setType("All");
                          setDescript(false);
                        }}
                      >
                        Clear All Filters
                      </button>{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div class="DatasetTitle" id="tbc">
                Price Prediction Corp
              </div>
              <div class="Table">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Field Label</StyledTableCell>
                        <StyledTableCell align="left">
                          Description
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          LookML Name
                        </StyledTableCell>
                        <StyledTableCell align="left">Type</StyledTableCell>
                        <StyledTableCell align="left">SQL</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRows.map((values) => {
                        return (
                          <StyledTableRow key={values.key}>
                            <StyledTableCell
                              component="placeholder"
                              scope="row"
                            >
                              <text class="yyy">{values.label}</text>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <text class="yyy">{values.description}</text>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <div class="yyy">{values.lookML}</div>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <text class="yyy">{values.type}</text>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <text class="yyy">{values.SQL}</text>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="footer_container"></footer>
    </>
  );
}

export default PricePredictionCorp;
