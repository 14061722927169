import React, { useState, useContext, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { Text } from "react-native";
import { Link } from "react-router-dom";

function HeaderBarRightSideIcons(props) {
  return (
    <div>
      <div className="user-info">
        {/* Search */}
        <Dropdown>
          <Dropdown.Toggle align="end" id="search" size="lg" className="custom-dropdown">
            <span
              class="material-icons"
              style={{
                fontSize: "24px",
                opacity: "0.6",
              }}
            >
              search
            </span>
          </Dropdown.Toggle>
        </Dropdown>

        {/* Data Dictionary */}
        <Dropdown>
          <Dropdown.Toggle align="end" id="search" size="lg" className="custom-dropdown">
            <Link exact to="/datadictionary">
              <span
                class="material-icons"
                style={{
                  fontSize: "24px",
                  opacity: "0.6",
                }}
              >
                menu_book
              </span>
            </Link>
          </Dropdown.Toggle>
        </Dropdown>

        {/* Tutorial */}
        <Dropdown>
          <Dropdown.Toggle align="end" id="search" size="lg" className="custom-dropdown">
            <Link exact to="/tutorials">
              <span
                class="material-icons"
                style={{
                  fontSize: "24px",
                  opacity: "0.6",
                }}
              >
                school
              </span>
            </Link>
          </Dropdown.Toggle>
        </Dropdown>

        {/* Marketplace */}
        {/* <Dropdown>
          <Dropdown.Toggle align="end" id="search" size="lg">
            <span
              class="material-icons"
              style={{
                fontSize: "24px",
                opacity: "0.6",
              }}
            >
              storefront
            </span>
          </Dropdown.Toggle>
        </Dropdown> */}

        {/* UserInfo */}
        <Dropdown>
          <Dropdown.Toggle align="end" id="user" size="lg" className="custom-dropdown">
            <span
              class="material-icons"
              style={{
                fontSize: "24px",
                opacity: "0.6",
                color: "rgba(255, 255, 255, 1);",
              }}
            >
              account_circle
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu className="user-menu">
            <Dropdown.Item>
              <Text
                backgroundColor="#131722"
                style={{
                  fontSize: 14,
                  color: "black",
                  marginLeft: "3px",
                  fontWeight: "bold",
                }}
                color={props.mainTxtColor}
                fontSize={props.subHeaderFS}
              >
                {props.username}
              </Text>
            </Dropdown.Item>
            <Dropdown.Item>
              <Text
                backgroundColor="#131722"
                style={{
                  fontSize: 14,
                  color: "black",
                  marginLeft: "3px",
                  fontWeight: "450",
                }}
                color={props.mainTxtColor}
                fontSize={props.subHeaderFS}
              >
                Account
              </Text>
            </Dropdown.Item>
            <Dropdown.Item>
              <Text
                backgroundColor="#131722"
                style={{
                  fontSize: 14,
                  color: "black",
                  marginLeft: "3px",
                  fontWeight: "450",
                }}
                color={props.mainTxtColor}
                fontSize={props.subHeaderFS}
              >
                History
              </Text>
            </Dropdown.Item>
            <Dropdown.Item>
              <Text
                backgroundColor="#131722"
                style={{
                  fontSize: 14,
                  color: "black",
                  marginLeft: "3px",
                  fontWeight: "450",
                }}
                color={props.mainTxtColor}
                fontSize={props.subHeaderFS}
              >
                Schedules
              </Text>
            </Dropdown.Item>
            <Dropdown.Item>
              <Text
                backgroundColor="#131722"
                style={{
                  fontSize: 14,
                  color: "black",
                  marginLeft: "3px",
                  fontWeight: "450",
                }}
                color={props.mainTxtColor}
                fontSize={props.subHeaderFS}
              >
                Sessions
              </Text>
            </Dropdown.Item>
            <Dropdown.Item onClick={props.handleLogOut}>
              <Text
                backgroundColor="#131722"
                style={{
                  fontSize: 14,
                  color: "black",
                  marginLeft: "3px",
                  fontWeight: "450",
                }}
                color={props.mainTxtColor}
                fontSize={props.subHeaderFS}
              >
                Log Out
              </Text>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

export default HeaderBarRightSideIcons;
