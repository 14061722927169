import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useState, useContext, useEffect } from "react";

import TopOfPage from "./components/TopOfPage";
import Home from "./components/Home";
import Screeners from "./components/Screeners";
import Forex from "./components/Forex";
import Stock from "./components/Stock";
import Bonds from "./components/Bond";
import Crypto from "./components/Crypto";
import MarketData from "./components/MarketData";
import RelVal from "./components/RelVal";
import RelValPlot from "./components/RelValPlot";
import DataDictionary from "./components/DataDictionary";
import Tutorials from "./components/Tutorials";
import InsightsIframe from "./components/InsightsIframe";
import SmartContracts from "./components/SmartContracts";
import Tearsheets from "./components/Tearsheets";
import Insights from "./components/Insights";
import Models from "./components/Models";
import ModelIframe from "./components/ModelIframe";
import HeaderBar from "./components/HeaderBar";
import OTCTrade from "./components/OTCTrade";
import Trade from "./components/Trade";
import ETrade from "./components/ETrade";
import AUTTokens from "./components/AUTTokens";
import NewNavigation from "./components/NewNavigation";
import FourOFour from "./components/FourOFour.js";
import MergentFISD from "./components/DataDictionary Datasets/MergentFISD";
import TraceEnhanced from "./components/DataDictionary Datasets/TraceEnhanced";
import CompustatFinancialFundamental from "./components/DataDictionary Datasets/CompustatFinancialFundamental";
import RelValCorp from "./components/DataDictionary Datasets/RelValCorp";
import RelValMuni from "./components/DataDictionary Datasets/RelValMuni";
import QuantstatsCusips from "./components/DataDictionary Datasets/QuantstatsCusips";
import RecommendationEngine from "./components/DataDictionary Datasets/RecommendationEngine";
import PricePredictionCorp from "./components/DataDictionary Datasets/PricePredictionCorp";
import RiskPredictionMuni from "./components/DataDictionary Datasets/RiskPredictionMuni";
import RiskPredictionCorp from "./components/DataDictionary Datasets/RiskPredictionCorp";
import TestDataDictionary from "./components/DataDictionary Datasets/TestDataDictionary";
import PricePredictionMuni from "./components/DataDictionary Datasets/PricePredictionMuni";
import CorporateForecast from "./components/DataDictionary Datasets/CorporateForecast";
import MSRB_EMMA from "./components/DataDictionary Datasets/MSRB_EMMA";
import FINRA_CRSP from "./components/DataDictionary Datasets/FINRA_CRSP";
import MuniForecast from "./components/DataDictionary Datasets/MunicipalForecast";
import BoardEx from "./components/DataDictionary Datasets/BoardEx";
import RavenPackSenti from "./components/DataDictionary Datasets/RavenPackSentiment";
import Bloomberg from "./components/DataDictionary Datasets/Bloomberg";
import Benchmark from "./components/Benchmark";
import Tearsheet from "./components/Tearsheet";
import CoPilot from "./components/Copilot";
import CoPilotQuestions from "./components/CopilotQuestions";
import ChatButton from "./components/ChatButton";
import CryptoTearsheet from "./components/CryptoTearsheet";

const RenderedWebpage = () => {
  return (
    <div className="page">
      <div style={{ display: "inline" }}>
        <HeaderBar />
        {/* <Navigation /> */}
        <div className="headNav">{<NewNavigation />}</div>
      </div>
      <div>
        <TopOfPage>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/forex" element={<Forex />} />
            <Route path="/screeners" element={<Screeners />} />
            <Route path="/stock" element={<Stock />} />
            <Route path="/bond" element={<Bonds />} />
            <Route path="/crypto" element={<Crypto />} />
            <Route path="/copilot" element={<CoPilot />} />
            <Route path="/copilot-question" element={<CoPilotQuestions />} />
            <Route path="/market-data" element={<MarketData />} />
            <Route path="/rel-val" element={<RelVal />} />
            <Route path="rel-val-plot" element={<RelValPlot />} />
            <Route path="/datadictionary" element={<DataDictionary />} />
            <Route path="/tutorials" element={<Tutorials />} />
            <Route path="/insights-iframe" element={<InsightsIframe />} />
            <Route path="/smart-contract" element={<SmartContracts />} />
            <Route path="/tearsheets" element={<Tearsheets />} />
            <Route path="/insights" element={<Insights />} />
            <Route path="/models" element={<Models />} />
            <Route path="/model-iframe" element={<ModelIframe />} />
            <Route path="/etrade" element={<ETrade />} />
            <Route path="/trade" element={<Trade />} />
            <Route path="/otc-trade" element={<OTCTrade />} />
            <Route path="/aut-tokens" element={<AUTTokens />} />
            <Route path="/four-o-four" element={<FourOFour />} />
            <Route path="/datadictionary/bloomberg" element={<Bloomberg />} />
            <Route path="*" element={<FourOFour />} />
            <Route
              path="/datadictionary/mergent_fisd"
              element={<MergentFISD />}
            />
            <Route
              path="/datadictionary/trace_enhanced"
              element={<TraceEnhanced />}
            />
            <Route
              path="/datadictionary/compustat_financial_fundamental"
              element={<CompustatFinancialFundamental />}
            />
            <Route path="/datadictionary/relval_corp" element={<RelValCorp />} />
            <Route path="/datadictionary/relval_muni" element={<RelValMuni />} />
            <Route
              path="/datadictionary/quantistats_cusips"
              element={<QuantstatsCusips />}
            />
            <Route
              path="/datadictionary/rec_engine"
              element={<RecommendationEngine />}
            />
            <Route
              path="/datadictionary/pricepred_corp"
              element={<PricePredictionCorp />}
            />
            <Route
              path="/datadictionary/riskpred_muni"
              element={<RiskPredictionMuni />}
            />
            <Route
              path="/datadictionary/TestDataDictionary"
              element={<TestDataDictionary />}
            />
            <Route
              path="/datadictionary/riskpred_corp"
              element={<RiskPredictionCorp />}
            />
            <Route
              path="/datadictionary/pricepred_muni"
              element={<PricePredictionMuni />}
            />
            <Route
              path="/datadictionary/corp_forecast"
              element={<CorporateForecast />}
            />
            <Route path="/datadictionary/msrb_emma" element={<MSRB_EMMA />} />
            <Route path="/datadictionary/finra_crsp" element={<FINRA_CRSP />} />
            <Route
              path="/datadictionary/muni_forecast"
              element={<MuniForecast />}
            />
            <Route path="/datadictionary/boardex" element={<BoardEx />} />
            <Route
              path="/datadictionary/raven_pack_senti"
              element={<RavenPackSenti />}
            />
            <Route path="/benchmark" element={<Benchmark />} />
            <Route path="/bondtearsheet" element={<Tearsheet isCrypto={false} />} />
            <Route path="/cryptotearsheet" element={<CryptoTearsheet />} />
          </Routes>
        </TopOfPage>
        <main
          className="relative overflow-auto bg-gray-100 dark:bg-gray-800"
        >
          <div className="flex items-start justify-between">
            {/* <Sidebar /> */}
            <ChatButton />
          </div>
        </main>
      </div>
    </div>
  );
};
export default RenderedWebpage;
