import React, { useState, useEffect } from "react";
import "./dictionaryss.css";
import { NavLink } from "react-router-dom";
import {
  forwardRef,
  AnchorHTMLAttributes,
  HTMLAttributes,
  HTMLProps,
} from "react";
import { Text } from "react-native";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import JSONDATA from "./DataDictionary Datasets/Sidebar.json";
import TESTTABLE from "./DataDictionary Datasets/DataDictionaryJSONs/DataDictionary_Mergent FISD.json";
import NAV from "react-bootstrap/Nav";

import { DocumentMultiple } from "styled-icons/fluentui-system-filled";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(28, 33, 49)",
    color: "rgba(147, 152, 160)",
    fontSize: 24,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    color: "rgba(147, 152, 160)",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "rgba(32, 40, 60)",

  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },

  "&:hover": {
    backgroundColor: "rgba(83, 85, 91)",
  },
}));

function createData(label, description, lookML, type, SQL) {
  return { label, description, lookML, type, SQL };
}

function updateTable(tableName) {
  let rows = [];
  for (let i = 0; i < tableName.length; i++) {
    rows.push(
      createData(
        tableName[i].Fl,
        tableName[i].D,
        tableName[i].LML,
        tableName[i].Type,
        tableName[i].SQL
      )
    );
  }
  return rows;
}

function sieve(rows, descript, type) {
  let filteredRows = [];
  for (let i = 0; i < rows.length; i++) {
    // document.write(rows[i]);
    let include = true;
    if (descript) {
      if (rows[i].description == "") {
        include = false;
      }
    }
    if (type != "All") {
      if (rows[i].type != type) {
        include = false;
      }
    }
    if (include) {
      filteredRows.push(rows[i]);
    }
  }
  return filteredRows;
}

function DataDictionary() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerms, setSearchTerms] = useState("");
  const [descript, setDescript] = useState(false);
  const [type, setType] = useState("All");
  const rows = updateTable(TESTTABLE);
  const filteredRows = sieve(rows, descript, type);

  return (
    <>
      <div align="center" width="100%" height="80px">
        <Text
          backgroundColor="#131722"
          style={{
            lineHeight: "80px",
            fontSize: 45,
            color: "white",
            marginTop: 10,
          }}
        >
          Data Dictionary
        </Text>
      </div>
      <div style={{ padding: 15 }}>
        <div class="xyz">
          <div id="mySidebar" class="sidebar" width="250px">
            <div className="ModelTitle">Select a Model</div>

            <select className="DropdownBox">
              <option className="DropdownText"> Fixed Income</option>
              <option className="DropdownText"> Muni Price Prediction</option>
              <option className="DropdownText"> Corperate Forecast</option>
              <option className="DropdownText"> Municipal Forecast</option>
            </select>
            <div className="SeperationBar" />
            <div className="ExploreTitle">Explores</div>

            <div>
              <input
                className="SearchBox"
                placeholder="Search Model"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
              ></input>
              {JSONDATA.filter((val) => {
                if (searchTerm == "") {
                  return val;
                } else if (
                  val.Model.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                  return val;
                }
              }).map((val, key) => {
                return (
                  <NAV className="flex-column">
                    <NavLink exact to={val.URL} class="hellohello" key={key}>
                      {val.Model}
                    </NavLink>
                  </NAV>
                );
              })}
            </div>
          </div>

          <div className="Embed">
            <div class="background">
              {/*
            <div class="container-horiz">
              <div class="FixedIncomeTitle">Fixed Income</div>
              <div class="ExploreButton">Explore</div>
              <div class="ExploreButton">View Options</div>
            </div>

            <div class="FieldSelectTitle">
              Select a field for more information.
            </div>
            <input
              className="FilterBar"
              placeholder="Filter fields in this Explore"
              onChange={(event) => {
                setSearchTerms(event.target.value);
              }}
            ></input>
            <div class="container-horiz">
              <div class="container-vertical">
              <div class="FilterTitle">Filter</div>
                <div> <button class={`FilterButton ${descript ? "ActiveButton" : "FilterButton"}`} 
                  onClick={() => {if (descript) {setDescript(false)} else {setDescript(true)}}}>Has Description</button> </div>
                {/* <FilterOpt name="Has Description" property="Description"> </FilterOpt> */}
              <div>
                {/* <Filter label="Has Description" property="Description"/> */}
              </div>
              {/* <div> <button onClick={() => null} class="FilterButton">Has Tags</button> </div>
                <div> <button onClick={() => null} class="FilterButton">Has Comments</button> </div> */}
            </div>
            {/* <div class="container-vertical">
                <div class="FilterTitle">Fields</div>
                <div class="container-horiz">
                  <div class="FilterButton">Dimension</div>
                  <div class="FilterButton">Measure</div>
                </div>
              </div> */}
            {/* <div class="container-vertical">
                <div class="FilterTitle">Has Tags</div>
                <div class="container-horiz">
                  <div class="FilterButton">Yes</div>
                  <div class="FilterButton">No</div>
                </div>
              </div> */}
            {/* <div class="container-vertical">
                <div class="FilterTitle">Has Comments</div>
                <div class="container-horiz">
                  <div class="FilterButton">Yes</div>
                  <div class="FilterButton">No</div>
                </div>
              </div> */}
            {/* <div class="container-vertical">
                <div class="FilterTitle">Type</div>
                <div class="container-horiz">
                  <div> <button class={"FilterButton"} 
                    onClick={() => {setType("Number")}}>Number</button> </div>
                  <div> <button class={"FilterButton"} 
                    onClick={() => {setType("String")}}>String</button> </div>
                  <div> <button class={"FilterButton"} 
                    onClick={() => {setType("Date time")}}>Date</button> </div>
                    <div> <button class={"FilterButton"}
                    onClick={() => {setType("Yes/No")}}>Yes/No</button> </div>
                </div>
                <div></div>
                <div class="container-horiz">
                  <div> <button class={"FilterButton"}
                    onClick={() => {setType("Average")}}>Average</button> </div>
                  <div> <button class={"FilterButton"}
                    onClick={() => {setType("Count")}}>Count</button> </div>
                  <div> <button class={"FilterButton"}
                    onClick={() => {setType("Sum")}}>Sum</button> </div>
                    <div> <button class={"FilterButton"}
                    onClick={() => {setType("Unquoted")}}>Unquoted</button> </div>
                </div>
                <div></div>
                <div class="container-horiz">
                <div> <button class={"FilterButton"}
                    onClick={() => {setType("All")}}>Clear Type Filters</button> </div>
                </div>
              </div>
            </div>  */}
            <p></p>
            <p></p>
            <div class="DefaultPadding"></div>
            <div class="Default">
              <img
                src="https://marketplace-api.looker.com/app-assets/data_dictionary_2x.png"
                alt="Empty Image"
                width="30%"
              ></img>
              <div className="SeperationBar" />
              <div class="DefaultExploreText">
                Click on one of the Explores to the left to begin searching
                through your data. You’ll see labels, descriptions, SQL
                definitions, and more for each field.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <footer class="footer_container"></footer>
    </>
  );
}

export default DataDictionary;
