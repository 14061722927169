import { Text } from "react-native";
import React, { useState, useContext } from "react";
import { AppContext } from "../WebContextProvider.js";
import { NavLink } from "react-router-dom";
import Section from "./Section.jsx";
import Bar from "./Bar";

export const bgColor = {
  backgroundColor: "#131315",
};

const headerStyle = {
  // backgroundColor: "#131722",
  backgroundColor: "#131315",
  borderBottom: "solid",
  borderBottomColor: "#6c6c6c",
  borderBottomWidth: "1px",
};

const boxStyle = {
  width: "400px",
  height: "300px",
  borderRadius: "20px",
  backgroundColor: "#1f2436",
  margin: "10px",
};

const miniBoxStyle = {
  width: "100%",
  height: "50px",
  backgroundColor: "#3a4346",
  textAlign: "center",
  // borderBottomLeftRadius: '10px',
  // borderBottomRightRadius: '10px',
};

const miniBoxStyle2 = {
  width: "100%",
  height: "50px",
  backgroundColor: "#3a4346",
  textAlign: "center",
  borderBottomLeftRadius: "20px",
  borderBottomRightRadius: "20px",
};

const miniButtonStyle = {
  color: "black",
  backgroundColor: "#c3c3c3",
  borderColor: "#c3c3c3",
  // width: '93',
  width: "116",
  height: "33",
  fontSize: "15",
  marginTop: "5",
};

export const mainTextColor = {
  color: "#9B9EA3",
};

export const label1 = {
  color: "#9B9EA3",
  fontSize: "30",
  textAlign: "center",
  lineHeight: "50px",
  paddingLeft: "13",
  paddingRight: "13",
};

const label2 = {
  color: "black",
  fontSize: "23",
  lineHeight: "50px",
  textDecoration: "none",
};

export const iconStyle = {
  color: "#9B9EA3",
};

const menuBackgroundColor = "#1f2436";

const buttonHighlightColor = "#2A2E39";

function highlightBoxButtonBackground(e) {
  let targ;
  let targName = e.target.className;
  if (e.target.className.substring(0, 3) === "img") {
    targ = e.target.parentNode.parentNode.parentNode;
  } else {
    switch (targName.substring(targName.length - 3, targName.length)) {
      case "box":
        targ = e.target;
        break;
      case "mb2":
        targ = e.target.parentNode.parentNode.parentNode;
        break;
      case "mb3":
        targ = e.target.parentNode.parentNode.parentNode;
        break;
      case "but":
        targ = e.target.parentNode.parentNode.parentNode.parentNode.parentNode;
        console.log(targ);
        break;
      default:
        if (targName.substring(0, 4) === "Flex") {
          targ = e.target.parentNode.parentNode;
        } else {
          targ = e.target.parentNode.parentNode.parentNode.parentNode;
        }
        break;
    }
  }

  targ.style.backgroundColor = buttonHighlightColor;
  targ.children[0].children[1].children[0].style.backgroundColor =
    buttonHighlightColor;
  targ.children[0].children[1].children[1].style.backgroundColor =
    buttonHighlightColor;
}

function unhighlightBoxButtonBackground(e) {
  let targ;
  let targName = e.target.className;
  if (e.target.className.substring(0, 3) === "img") {
    targ = e.target.parentNode.parentNode.parentNode;
  } else {
    switch (targName.substring(targName.length - 3, targName.length)) {
      case "box":
        targ = e.target;
        break;
      case "mb2":
        targ = e.target.parentNode.parentNode.parentNode;
        break;
      case "mb3":
        targ = e.target.parentNode.parentNode.parentNode;
        break;
      case "but":
        targ = e.target.parentNode.parentNode.parentNode.parentNode.parentNode;
        console.log(targ);
        break;
      default:
        if (targName.substring(0, 4) === "Flex") {
          targ = e.target.parentNode.parentNode;
        } else {
          targ = e.target.parentNode.parentNode.parentNode.parentNode;
        }
        break;
    }
  }

  targ.style.backgroundColor = menuBackgroundColor;
  targ.children[0].children[1].children[0].style.backgroundColor = "#3a4346";
  targ.children[0].children[1].children[1].style.backgroundColor = "#3a4346";
}

function underlineText(e) {
  e.target.style.textDecoration = "none";
  e.target.style.textDecorationColor = "black";
}

function removeUnderline(e) {
  e.target.style.textDecoration = "none";
}

//Function to make cursor a hand on mouse over
function cursorHand(e) {
  e.target.style.cursor = "pointer";
}

// class Dashboards extends React.Component {
//   render() {
const Dashboards = () => {
  const { setVideo, setEmbedID, setStudioLink } = useContext(AppContext);
  let initialSubheaderFontSize;
  if (window.innerWidth > 568) {
    initialSubheaderFontSize = 37;
  } else {
    initialSubheaderFontSize = 31;
  }

  const [subheaderFontSize, setSubheaderFontSize] = useState(
    initialSubheaderFontSize
  );
  return (
    <div style={{ minHeight: "100vh" }}>
      <div align="center" width="100%" height="80px">
        <Text
          backgroundColor="#131722"
          style={{
            lineHeight: "80px",
            fontSize: 45,
            color: "white",
            marginTop: 10,
          }}
        >
          Insights
        </Text>
      </div>

      <Bar />
      <br />

      {/* Dashboards Buttons */}
      <div className="container-fluid">
        <div class="card-deck-wraper">
          <div class="card-deck d-flex flex-type justify-content-center">
            <div style={{ width: "80%", paddingBottom: 20 }}>
              <div className="sectionGrid">
                <Section
                  to="/insights-iframe"
                  onClick={() => {
                    setStudioLink(
                      "https://lookerstudio.google.com/embed/reporting/a01f1f6a-bb2a-4e6c-a3a8-e0a953aef661/page/9QLoC"
                    );
                  }}
                  onClickTutorial={() => {
                    setVideo("Board Ex");
                    setEmbedID("EckpYlIgrHQ");
                  }}
                  icon="pie_chart"
                  header="BoardEx"
                />
                <Section
                  to="/insights-iframe"
                  onClick={() => {
                    setStudioLink(
                      "https://datastudio.google.com/embed/reporting/f89e87ee-3e3a-47e5-b78c-ed6086f493d1/page/PTXzC"
                    );
                  }}
                  onClickTutorial={() => {
                    setVideo("Insights Dashboard");
                    setEmbedID("ePaGdkVsloE");
                  }}
                  icon="insights"
                  header="Insights"
                />
                <Section
                  to="/insights-iframe"
                  onClick={() => {
                    setStudioLink(
                      "https://datastudio.google.com/embed/reporting/7b0864a0-e858-4907-b2e7-813ff2da0bcd/page/3xPyC"
                    );
                  }}
                  onClickTutorial={() => {
                    setVideo("Corporate Issuance");
                    setEmbedID("GcVVejAWuJE");
                  }}
                  icon="inventory"
                  header="Corp Bond Issuance"
                />
                <Section
                  to="/insights-iframe"
                  onClick={() => {
                    setStudioLink(
                      "https://datastudio.google.com/embed/reporting/51c07a20-e909-4d31-948f-218066c4b1b2/page/ez6xC"
                    );
                  }}
                  onClickTutorial={() => {
                    setVideo("Corporate Redemption");
                    setEmbedID("zbl7YavZhqQ");
                  }}
                  icon="analytics"
                  header="Corp Redemption"
                />
                <Section
                  to="/insights-iframe"
                  onClick={() => {
                    setStudioLink(
                      "https://lookerstudio.google.com/embed/reporting/a2df3521-7c51-4061-a560-d16f26fa454a/page/VEZrC"
                    );
                  }}
                  onClickTutorial={() => {
                    setVideo("Corporate Bond Trades");
                    setEmbedID("Qrld_14tCtI");
                  }}
                  icon="currency_exchange"
                  header="Corp Bond Trades"
                />
                <Section
                  to="/insights-iframe"
                  onClick={() => {
                    setStudioLink(
                      "https://datastudio.google.com/embed/reporting/904c9523-57f0-44ee-b56f-338045afb734/page/UQxyC"
                    );
                  }}
                  onClickTutorial={() => {
                    setVideo("Bond Intelligence Intro");
                    setEmbedID("NJlUiWPknb4");
                  }}
                  icon="handshake"
                  header="Corp Trades - TRACE"
                />
                <Section
                  to="/insights-iframe"
                  onClick={() => {
                    setStudioLink(
                      "https://lookerstudio.google.com/embed/reporting/5e4a40a4-a20f-42f0-b927-a783d9699438/page/ghRuC"
                    );
                  }}
                  onClickTutorial={() => {
                    setVideo("Corporate Fundamentals");
                    setEmbedID("-qvftdUmDzs");
                  }}
                  icon="foundation"
                  header="Corp Fundamentals"
                />
                <Section
                  to="/insights-iframe"
                  onClick={() => {
                    setStudioLink(
                      "https://lookerstudio.google.com/embed/reporting/4d843212-6ae8-4bc6-978b-b6e5383426b6/page/FmwtC"
                    );
                  }}
                  onClickTutorial={() => {
                    setVideo("Corporate Financial Ratios");
                    setEmbedID("oTfCDCZbPP8");
                  }}
                  icon="payments"
                  header="Corp Financial Ratios"
                />
                <Section
                  to="/insights-iframe"
                  onClick={() => {
                    setStudioLink(
                      "https://datastudio.google.com/embed/reporting/370a1c02-7ee7-4317-8b88-fb0a7278d415/page/9IrxC"
                    );
                  }}
                  onClickTutorial={() => {
                    setVideo("Municipal Bond Issuances");
                    setEmbedID("WS21HhaVcV4");
                  }}
                  icon="paid"
                  header="Muni Bond Issuances"
                />
                <Section
                  to="/insights-iframe"
                  onClick={() => {
                    setStudioLink(
                      "https://datastudio.google.com/embed/reporting/10def2c5-89f5-4645-9987-7245bbd87010/page/VVDyC"
                    );
                  }}
                  onClickTutorial={() => {
                    setVideo("Municipal Bond Trades");
                    setEmbedID("nox_Y4Xo5c0");
                  }}
                  icon="sell"
                  header="Municipal Bond Trades"
                />
                <Section
                  to="/insights-iframe"
                  onClick={() => {
                    setStudioLink(
                      "https://lookerstudio.google.com/embed/reporting/cc41118f-c268-4804-bd55-82e287372a39/page/XORyC"
                    );
                  }}
                  onClickTutorial={() => {
                    setVideo("Sentiment Analysis");
                    setEmbedID("1L3w46SR9PM");
                  }}
                  icon="trending_up"
                  header="Sentiment Analysis"
                />
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboards;
