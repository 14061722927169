import { Text } from "react-native";
import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { NavLink } from "react-router-dom";
import "./App.css";
import { AppContext } from "../WebContextProvider.js";
import Section from "./Section.jsx";

const CUSIP_options = [
  { value: "036015PL3", label: "036015PL3" },
  { value: "036054BG8", label: "036054BG8" },
  { value: "036054AN4", label: "036054AN4" },
  { value: "036015PN9", label: "036015PN9" },
  { value: "036054BM5", label: "036054BM5" },
  { value: "036054BQ6", label: "036054BQ6" },
  { value: "036015PG4", label: "036015PG4" },
  { value: "036090CA4", label: "036090CA4" },
  { value: "036090CH9", label: "036090CH9" },
  { value: "036090BW7", label: "036090BW7" },
  { value: "036054AM6", label: "036054AM6" },
  { value: "036090BV9", label: "036090BV9" },
  { value: "036054BL7", label: "036054BL7" },
  { value: "036015PM1", label: "036015PM1" },
  { value: "036054AK0", label: "036054AK0" },
  { value: "036054BP8", label: "036054BP8" },
  { value: "036015PJ8", label: "036015PJ8" },
  { value: "036015PA7", label: "036015PA7" },
  { value: "036015PH2", label: "036015PH2" },
  { value: "036015NV3", label: "036015NV3" },
  { value: "036054AL8", label: "036054AL8" },
  { value: "036054AF1", label: "036054AF1" },
  { value: "036015PD1", label: "036015PD1" },
  { value: "036015PC3", label: "036015PC3" },
  { value: "036054AG9", label: "036054AG9" },
  { value: "036015PK5", label: "036015PK5" },
  { value: "036054AY0", label: "036054AY0" },
  { value: "036054AV6", label: "036054AV6" },
  { value: "036054AJ3", label: "036054AJ3" },
  { value: "036054AH7", label: "036054AH7" },
  { value: "036054BD5", label: "036054BD5" },
  { value: "036054BB9", label: "036054BB9" },
  { value: "036054BJ2", label: "036054BJ2" },
  { value: "036054AZ7", label: "036054AZ7" },
  { value: "036090CC0", label: "036090CC0" },
  { value: "036090CG1", label: "036090CG1" },
  { value: "036054AW4", label: "036054AW4" },
  { value: "036090CJ5", label: "036090CJ5" },
  { value: "036090CB2", label: "036090CB2" },
  { value: "036054AX2", label: "036054AX2" },
  { value: "036090BY3", label: "036090BY3" },
  { value: "036054AE4", label: "036054AE4" },
  { value: "033393HG7", label: "033393HG7" },
  { value: "033393HH5", label: "033393HH5" },
  { value: "033393HZ5", label: "033393HZ5" },
  { value: "033393HY8", label: "033393HY8" },
  { value: "033393HL6", label: "033393HL6" },
  { value: "033393JN0", label: "033393JN0" },
  { value: "033393JF7", label: "033393JF7" },
  { value: "033393HQ5", label: "033393HQ5" },
  { value: "033455BC9", label: "033455BC9" },
  { value: "033393HB8", label: "033393HB8" },
  { value: "033393HF9", label: "033393HF9" },
  { value: "033393HP7", label: "033393HP7" },
  { value: "033393HJ1", label: "033393HJ1" },
  { value: "033393FS3", label: "033393FS3" },
  { value: "033393HV4", label: "033393HV4" },
  { value: "033393JJ9", label: "033393JJ9" },
  { value: "033393GH6", label: "033393GH6" },
  { value: "033393FT1", label: "033393FT1" },
  { value: "033393FR5", label: "033393FR5" },
  { value: "033393HT9", label: "033393HT9" },
  { value: "033393HR3", label: "033393HR3" },
  { value: "033393HN2", label: "033393HN2" },
  { value: "033452AD5", label: "033452AD5" },
  { value: "033455BB1", label: "033455BB1" },
  { value: "033393HM4", label: "033393HM4" },
  { value: "033393JC4", label: "033393JC4" },
  { value: "033393FY0", label: "033393FY0" },
  { value: "033393JH3", label: "033393JH3" },
  { value: "033455AY2", label: "033455AY2" },
  { value: "033470BB0", label: "033470BB0" },
  { value: "033393HK8", label: "033393HK8" },
  { value: "033393JD2", label: "033393JD2" },
  { value: "033393JG5", label: "033393JG5" },
  { value: "033393GR4", label: "033393GR4" },
  { value: "033393GE3", label: "033393GE3" },
  { value: "033455AS5", label: "033455AS5" },
  { value: "033393JM2", label: "033393JM2" },
  { value: "033455BA3", label: "033455BA3" },
  { value: "033393HX0", label: "033393HX0" },
  { value: "033470BD6", label: "033470BD6" },
  { value: "033393HW2", label: "033393HW2" },
  { value: "033393JE0", label: "033393JE0" },
  { value: "033455AV8", label: "033455AV8" },
  { value: "033393HD4", label: "033393HD4" },
  { value: "033393JL4", label: "033393JL4" },
  { value: "033470BQ7", label: "033470BQ7" },
  { value: "033470BM6", label: "033470BM6" },
  { value: "033470BW4", label: "033470BW4" },
  { value: "033470AY1", label: "033470AY1" },
  { value: "033470BP9", label: "033470BP9" },
  { value: "033455BK1", label: "033455BK1" },
  { value: "033470BE4", label: "033470BE4" },
  { value: "033470BK0", label: "033470BK0" },
  { value: "033470BV6", label: "033470BV6" },
  { value: "033470BJ3", label: "033470BJ3" },
  { value: "033393GW3", label: "033393GW3" },
  { value: "033393FV6", label: "033393FV6" },
  { value: "033393GU7", label: "033393GU7" },
  { value: "033470BS3", label: "033470BS3" },
  { value: "033470BX2", label: "033470BX2" },
  { value: "033393KD0", label: "033393KD0" },
  { value: "033393JT7", label: "033393JT7" },
  { value: "033393KL2", label: "033393KL2" },
  { value: "033393JX8", label: "033393JX8" },
  { value: "033393KK4", label: "033393KK4" },
  { value: "033393FZ7", label: "033393FZ7" },
  { value: "033393KC2", label: "033393KC2" },
  { value: "033393JP5", label: "033393JP5" },
  { value: "033393GL7", label: "033393GL7" },
  { value: "033393KG3", label: "033393KG3" },
  { value: "033393GB9", label: "033393GB9" },
  { value: "033393KB4", label: "033393KB4" },
  { value: "033470BC8", label: "033470BC8" },
  { value: "033393FU8", label: "033393FU8" },
  { value: "033393KJ7", label: "033393KJ7" },
  { value: "033393JW0", label: "033393JW0" },
  { value: "033393JR1", label: "033393JR1" },
  { value: "033393JY6", label: "033393JY6" },
  { value: "033393JQ3", label: "033393JQ3" },
  { value: "033393KF5", label: "033393KF5" },
  { value: "010399AQ6", label: "010399AQ6" },
  { value: "01039WAZ5", label: "01039WAZ5" },
  { value: "010399CS0", label: "010399CS0" },
  { value: "010399CR2", label: "010399CR2" },
  { value: "01039VAP9", label: "01039VAP9" },
  { value: "01039VAJ3", label: "01039VAJ3" },
  { value: "01039VAD6", label: "01039VAD6" },
  { value: "010399BY8", label: "010399BY8" },
  { value: "01039VAM6", label: "01039VAM6" },
  { value: "010399BX0", label: "010399BX0" },
  { value: "010399CA9", label: "010399CA9" },
  { value: "010399CQ4", label: "010399CQ4" },
  { value: "010230LD0", label: "010230LD0" },
  { value: "010230LB4", label: "010230LB4" },
  { value: "010230LE8", label: "010230LE8" },
  { value: "010230KZ2", label: "010230KZ2" },
  { value: "010230KV1", label: "010230KV1" },
  { value: "010230KW9", label: "010230KW9" },
  { value: "010230LH1", label: "010230LH1" },
  { value: "010230KX7", label: "010230KX7" },
  { value: "013122SY3", label: "013122SY3" },
  { value: "013122TC0", label: "013122TC0" },
  { value: "013122TB2", label: "013122TB2" },
  { value: "013122TE6", label: "013122TE6" },
  { value: "013122QR0", label: "013122QR0" },
  { value: "013122TG1", label: "013122TG1" },
  { value: "013122SH0", label: "013122SH0" },
  { value: "013141BM7", label: "013141BM7" },
  { value: "038447ME5", label: "038447ME5" },
  { value: "013122TD8", label: "013122TD8" },
  { value: "013122TH9", label: "013122TH9" },
  { value: "013141BU9", label: "013141BU9" },
  { value: "013122UA2", label: "013122UA2" },
  { value: "013122TF3", label: "013122TF3" },
  { value: "013122SZ0", label: "013122SZ0" },
  { value: "013122SE7", label: "013122SE7" },
  { value: "013122ST4", label: "013122ST4" },
  { value: "013122TA4", label: "013122TA4" },
  { value: "013141CV6", label: "013141CV6" },
  { value: "013122SL1", label: "013122SL1" },
  { value: "013141BS4", label: "013141BS4" },
  { value: "013122SQ0", label: "013122SQ0" },
  { value: "013122QT6", label: "013122QT6" },
  { value: "013141CZ7", label: "013141CZ7" },
  { value: "038447MT2", label: "038447MT2" },
  { value: "013141DH6", label: "013141DH6" },
  { value: "013122NU6", label: "013122NU6" },
  { value: "013141CR5", label: "013141CR5" },
  { value: "038439FH3", label: "038439FH3" },
  { value: "013122TT3", label: "013122TT3" },
  { value: "013122RE8", label: "013122RE8" },
  { value: "013122TY2", label: "013122TY2" },
  { value: "013141BT2", label: "013141BT2" },
  { value: "038429KG0", label: "038429KG0" },
  { value: "013122QY5", label: "013122QY5" },
  { value: "013122TW6", label: "013122TW6" },
  { value: "013122SG2", label: "013122SG2" },
  { value: "013122TX4", label: "013122TX4" },
  { value: "013141BY1", label: "013141BY1" },
  { value: "013122SS6", label: "013122SS6" },
  { value: "013122TS5", label: "013122TS5" },
  { value: "013122QP4", label: "013122QP4" },
  { value: "013141BQ8", label: "013141BQ8" },
  { value: "013122SW7", label: "013122SW7" },
  { value: "013122SF4", label: "013122SF4" },
  { value: "013122SM9", label: "013122SM9" },
  { value: "013122SP2", label: "013122SP2" },
  { value: "013122SD9", label: "013122SD9" },
  { value: "013122QM1", label: "013122QM1" },
  { value: "013122QQ2", label: "013122QQ2" },
  { value: "013122NN2", label: "013122NN2" },
  { value: "013141BN5", label: "013141BN5" },
  { value: "013122NW2", label: "013122NW2" },
  { value: "013122QF6", label: "013122QF6" },
  { value: "038433AH1", label: "038433AH1" },
  { value: "038447MV7", label: "038447MV7" },
  { value: "013122QU3", label: "013122QU3" },
  { value: "038447MK1", label: "038447MK1" },
  { value: "00072AAA8", label: "00072AAA8" },
  { value: "00083G7E8", label: "00083G7E8" },
  { value: "00083GRK2", label: "00083GRK2" },
  { value: "00083JAE8", label: "00083JAE8" },
  { value: "00083JAF5", label: "00083JAF5" },
  { value: "00083JDN5", label: "00083JDN5" },
  { value: "00083JEF1", label: "00083JEF1" },
  { value: "00083JES3", label: "00083JES3" },
  { value: "00083JGR3", label: "00083JGR3" },
  { value: "00083JJV1", label: "00083JJV1" },
  { value: "00083JKM9", label: "00083JKM9" },
  { value: "00083JNP9", label: "00083JNP9" },
  { value: "00102EAA4", label: "00102EAA4" },
  { value: "00103XAC7", label: "00103XAC7" },
  { value: "001055AH5", label: "001055AH5" },
  { value: "00163MAA2", label: "00163MAA2" },
  { value: "00175KAC8", label: "00175KAC8" },
  { value: "00176LDK4", label: "00176LDK4" },
  { value: "00176LDN8", label: "00176LDN8" },
  { value: "00176LDP3", label: "00176LDP3" },
  { value: "00176LDV0", label: "00176LDV0" },
  { value: "00176LEC1", label: "00176LEC1" },
  { value: "00185WAB2", label: "00185WAB2" },
  { value: "001957BE8", label: "001957BE8" },
  { value: "001957BJ7", label: "001957BJ7" },
  { value: "00206RAM4", label: "00206RAM4" },
  { value: "00206RCA8", label: "00206RCA8" },
  { value: "00206RCC4", label: "00206RCC4" },
  { value: "00206RCW0", label: "00206RCW0" },
  { value: "00206RGJ5", label: "00206RGJ5" },
  { value: "00206RHC9", label: "00206RHC9" },
  { value: "00209UAD4", label: "00209UAD4" },
  { value: "00209UAE2", label: "00209UAE2" },
  { value: "00209UAF9", label: "00209UAF9" },
  { value: "002546265", label: "002546265" },
  { value: "002546349", label: "002546349" },
  { value: "002546364", label: "002546364" },
  { value: "002546414", label: "002546414" },
  { value: "002546554", label: "002546554" },
  { value: "00254ELY6", label: "00254ELY6" },
  { value: "00254EMT6", label: "00254EMT6" },
  { value: "002799AK0", label: "002799AK0" },
  { value: "002824AK6", label: "002824AK6" },
  { value: "002824BJ8", label: "002824BJ8" },
  { value: "00287YAG4", label: "00287YAG4" },
  { value: "00287YAK5", label: "00287YAK5" },
  { value: "00287YAN9", label: "00287YAN9" },
  { value: "003669AB4", label: "003669AB4" },
  { value: "003831AG9", label: "003831AG9" },
  { value: "004398AE3", label: "004398AE3" },
  { value: "00439TAE7", label: "00439TAE7" },
  { value: "00440EAJ6", label: "00440EAJ6" },
  { value: "004446AD2", label: "004446AD2" },
  { value: "00507UAM3", label: "00507UAM3" },
  { value: "00687BAA3", label: "00687BAA3" },
  { value: "00753CAD4", label: "00753CAD4" },
  { value: "007634AA6", label: "007634AA6" },
  { value: "00763MAN8", label: "00763MAN8" },
  { value: "007643AB5", label: "007643AB5" },
  { value: "00772BAB7", label: "00772BAB7" },
  { value: "00817YAL2", label: "00817YAL2" },
  { value: "008252AG3", label: "008252AG3" },
  { value: "00826KAC1", label: "00826KAC1" },
  { value: "00828DAN1", label: "00828DAN1" },
  { value: "00846UAD3", label: "00846UAD3" },
  { value: "008674AB9", label: "008674AB9" },
  { value: "00912XAF1", label: "00912XAF1" },
  { value: "00912XAH7", label: "00912XAH7" },
  { value: "00912XAJ3", label: "00912XAJ3" },
  { value: "00912XAL8", label: "00912XAL8" },
  { value: "00912XAS3", label: "00912XAS3" },
  { value: "009363AB8", label: "009363AB8" },
  { value: "009363AG7", label: "009363AG7" },
  { value: "009363AN2", label: "009363AN2" },
  { value: "009367AD5", label: "009367AD5" },
  { value: "01019A344", label: "01019A344" },
  { value: "01019A476", label: "01019A476" },
  { value: "01019A567", label: "01019A567" },
  { value: "01019A583", label: "01019A583" },
  { value: "01019A617", label: "01019A617" },
  { value: "01019A690", label: "01019A690" },
  { value: "01019M561", label: "01019M561" },
  { value: "01019M777", label: "01019M777" },
  { value: "01019MAC2", label: "01019MAC2" },
  { value: "01020G231", label: "01020G231" },
  { value: "01020G249", label: "01020G249" },
  { value: "01020G256", label: "01020G256" },
  { value: "01020G264", label: "01020G264" },
  { value: "01020G272", label: "01020G272" },
  { value: "01020G280", label: "01020G280" },
  { value: "01020G322", label: "01020G322" },
  { value: "01020G330", label: "01020G330" },
  { value: "01020G348", label: "01020G348" },
  { value: "01020G355", label: "01020G355" },
  { value: "01020G363", label: "01020G363" },
  { value: "01020G371", label: "01020G371" },
  { value: "01020G389", label: "01020G389" },
  { value: "01020G397", label: "01020G397" },
  { value: "01020G470", label: "01020G470" },
  { value: "010392EY0", label: "010392EY0" },
  { value: "011588AB6", label: "011588AB6" },
  { value: "011588AD2", label: "011588AD2" },
  { value: "011588AE0", label: "011588AE0" },
  { value: "014383AA1", label: "014383AA1" },
  { value: "014383AC7", label: "014383AC7" },
  { value: "01447UAA0", label: "01447UAA0" },
  { value: "01566MAB1", label: "01566MAB1" },
  { value: "016275AN9", label: "016275AN9" },
  { value: "018581AD0", label: "018581AD0" },
  { value: "018606AD5", label: "018606AD5" },
  { value: "018803AE6", label: "018803AE6" },
  { value: "018803AG1", label: "018803AG1" },
  { value: "01958XBD8", label: "01958XBD8" },
  { value: "02003MAA2", label: "02003MAA2" },
  { value: "02003MBQ6", label: "02003MBQ6" },
  { value: "020040AB7", label: "020040AB7" },
  { value: "02005NAF7", label: "02005NAF7" },
  { value: "02005NAR1", label: "02005NAR1" },
  { value: "02005NAT7", label: "02005NAT7" },
  { value: "02005NAU4", label: "02005NAU4" },
  { value: "02005NBB5", label: "02005NBB5" },
  { value: "02051PAC2", label: "02051PAC2" },
  { value: "02209SAD5", label: "02209SAD5" },
  { value: "02209SAK9", label: "02209SAK9" },
  { value: "02265QAA6", label: "02265QAA6" },
  { value: "023586AJ9", label: "023586AJ9" },
  { value: "023608AE2", label: "023608AE2" },
  { value: "023663AB3", label: "023663AB3" },
  { value: "02376XAA7", label: "02376XAA7" },
  { value: "02377AAA6", label: "02377AAA6" },
  { value: "02503YAF0", label: "02503YAF0" },
  { value: "025537AF8", label: "025537AF8" },
  { value: "025816BA6", label: "025816BA6" },
  { value: "025816BG3", label: "025816BG3" },
  { value: "025816BW8", label: "025816BW8" },
  { value: "025818EM3", label: "025818EM3" },
  { value: "0258M0BY4", label: "0258M0BY4" },
  { value: "0258M0CW7", label: "0258M0CW7" },
  { value: "0258M0CZ0", label: "0258M0CZ0" },
  { value: "0258M0DJ5", label: "0258M0DJ5" },
  { value: "0258M0EE5", label: "0258M0EE5" },
  { value: "0258M0EG0", label: "0258M0EG0" },
  { value: "02635PSP9", label: "02635PSP9" },
  { value: "02635PSQ7", label: "02635PSQ7" },
  { value: "02635PSS3", label: "02635PSS3" },
  { value: "02635PTQ6", label: "02635PTQ6" },
  { value: "026375AE5", label: "026375AE5" },
  { value: "026375AM7", label: "026375AM7" },
  { value: "02665WAB7", label: "02665WAB7" },
  { value: "02665WAC5", label: "02665WAC5" },
  { value: "02665WAT8", label: "02665WAT8" },
  { value: "02665WAV3", label: "02665WAV3" },
  { value: "02665WAW1", label: "02665WAW1" },
  { value: "02665WBK6", label: "02665WBK6" },
  { value: "02665WCE9", label: "02665WCE9" },
  { value: "026874AT4", label: "026874AT4" },
  { value: "02687QBW7", label: "02687QBW7" },
  { value: "027126AC5", label: "027126AC5" },
  { value: "029717AM7", label: "029717AM7" },
  { value: "030096AH4", label: "030096AH4" },
  { value: "03027XAC4", label: "03027XAC4" },
  { value: "03040WAB1", label: "03040WAB1" },
  { value: "03070QAF8", label: "03070QAF8" },
  { value: "031042AB0", label: "031042AB0" },
  { value: "031162AG5", label: "031162AG5" },
  { value: "031162AJ9", label: "031162AJ9" },
  { value: "032092AC2", label: "032092AC2" },
  { value: "032095AG6", label: "032095AG6" },
  { value: "03234AAC3", label: "03234AAC3" },
  { value: "032359AE1", label: "032359AE1" },
  { value: "032420AC5", label: "032420AC5" },
  { value: "032511BD8", label: "032511BD8" },
  { value: "032803AB4", label: "032803AB4" },
  { value: "03444RAA6", label: "03444RAA6" },
  { value: "034918AC6", label: "034918AC6" },
  { value: "03512TAC5", label: "03512TAC5" },
  { value: "035229CA9", label: "035229CA9" },
  { value: "035242AC0", label: "035242AC0" },
  { value: "035242AD8", label: "035242AD8" },
  { value: "03734PAC5", label: "03734PAC5" },
  { value: "037411AT2", label: "037411AT2" },
  { value: "037411AU9", label: "037411AU9" },
  { value: "037735CQ8", label: "037735CQ8" },
  { value: "037833AQ3", label: "037833AQ3" },
  { value: "037833BN9", label: "037833BN9" },
  { value: "037833BQ2", label: "037833BQ2" },
  { value: "037833CB4", label: "037833CB4" },
  { value: "03834AAA1", label: "03834AAA1" },
  { value: "038374AB0", label: "038374AB0" },
  { value: "03841XAB0", label: "03841XAB0" },
  { value: "038522AG3", label: "038522AG3" },
  { value: "038923AD0", label: "038923AD0" },
  { value: "03938LAS3", label: "03938LAS3" },
  { value: "03938LAT1", label: "03938LAT1" },
  { value: "03938LAU8", label: "03938LAU8" },
  { value: "039483AH5", label: "039483AH5" },
  { value: "04351LAA8", label: "04351LAA8" },
  { value: "04420QAD8", label: "04420QAD8" },
  { value: "04454CCB0", label: "04454CCB0" },
  { value: "04454CCW4", label: "04454CCW4" },
];

export const bgColor = {
  backgroundColor: "#131722",
};

export const mainTextColor = {
  color: "#9B9EA3",
};

export const label1 = {
  color: "#9B9EA3",
  fontSize: "30",
  textAlign: "center",
  lineHeight: "50px",
  paddingLeft: "13",
  paddingRight: "13",
};

const label2 = {
  color: "black",
  fontSize: "23",
  lineHeight: "50px",
  textDecoration: "none",
};

export const iconStyle = {
  color: "#9B9EA3",
};

function highlightButton(e) {
  // e.target.style.backgroundColor = "#302c3c";
  e.target.style.backgroundColor = "#2A2E39";
}

function unHighlightButton(e) {
  e.target.style.backgroundColor = "#1f2436";
}

function underlineText(e) {
  e.target.style.textDecoration = "underline";
  e.target.style.textDecorationColor = "black";
}

function removeUnderline(e) {
  e.target.style.textDecoration = "none";
}

function highlightXbutton(e) {
  e.target.style.backgroundColor = "#e0243c";
}

function unHighlightXButton(e) {
  e.target.style.backgroundColor = "#cc1c3c";
}

//Function to make cursor a hand on mouse over
function cursorHand(e) {
  e.target.style.cursor = "pointer";
}

// class Dashboards extends React.Component {
//   render() {
const OTCTrade = () => {
  const { setVideo, setEmbedID } = useContext(AppContext);
  let initialSubheaderFontSize;
  if (window.innerWidth > 568) {
    initialSubheaderFontSize = 37;
  } else {
    initialSubheaderFontSize = 31;
  }

  const [selectedCUSIP, setSelectedCUSIP] = useState("");
  const [bid, setBid] = useState(0);
  const [ask, setAsk] = useState(0);
  const [showCUSIP, setShowCUSIP] = useState("");
  const [showLiveData, setShowLiveData] = useState(false);
  const [clockState, setClockState] = useState();
  const [showClockState, setShowClockState] = useState();
  const [overlayLink, setOverlayLink] = useState("none");
  const [iframeLink, setIframeLink] = useState("");

  const [subheaderFontSize, setSubheaderFontSize] = useState(
    initialSubheaderFontSize
  );
  useEffect(() => {
    setInterval(() => {
      const date = new Date();
      setClockState(date.toLocaleTimeString());
    }, 1000);
  }, []);

  function displayLiveData() {
    console.log(showCUSIP);
    console.log(showLiveData);
    if (selectedCUSIP.value.length > 0) {
      setBid(102);
      setAsk(100);
      setShowLiveData(true);
      setShowCUSIP(selectedCUSIP);
      setShowClockState(clockState);
    }
  }
  function on(e) {
    setOverlayLink("block");
    setIframeLink(e);
  }
  function off() {
    setOverlayLink("none");
  }

  return (
    <div style={{ minHeight: "100vh" }}>
      <div align="center" width="100%" height="80px">
        <Text
          backgroundColor="#131722"
          style={{
            lineHeight: "80px",
            fontSize: 45,
            color: "white",
            marginTop: 10,
          }}
        >
          OTC Trade
        </Text>
      </div>

      {/* Smart Contract Buttons */}
      <div className="container-fluid py-4">
        <div class="card-deck-wraper">
          <div class="card-deck d-flex flex-row justify-content-center">
            {/* Price Maker Button */}

            <div style={{ paddingRight: 10, width: "30%" }}>
              <Section
                onClick={() => {
                  on("https://testlab.transficc.io/");
                }}
                icon="price_change"
                header="Price Maker"
              />
            </div>

            {/* Slider, button and table in the middle */}
            <center>
              {/* Space */}
              <div
                style={{
                  height: "15px",
                }}
              ></div>

              {/* Dropdown menu */}
              <Select
                id="sm1"
                maxWidth={200}
                placeholder="Bond CUSIP Value"
                onChange={setSelectedCUSIP}
                options={CUSIP_options}
                styles={{
                  option: (styles, state) => ({
                    ...styles,
                    color: "white",
                    backgroundColor: "#1F2436",
                    "&:hover": {
                      ...styles,
                      backgroundColor: "#6272FA",
                    },
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "white",
                    backgroundColor: "#1F2436",
                    cursor: "pointer",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "white",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "white",
                  }),
                }}
                isClearable
                value={selectedCUSIP}
                isFilterable
                showCreate
                // marginBottom="10px"
              />

              {/* Space */}
              <div
                style={{
                  height: "20px",
                }}
              ></div>

              {/* Button */}
              <button
                style={{
                  border: "none",
                  backgroundColor: "#1f2436",
                  fontSize: "25px",
                  color: "#9B9EA3",
                  padding: "13px 20px",
                  height: "60px",
                  width: "261px",
                  borderRadius: "10px",
                }}
                id="Market Data"
                onClick={displayLiveData}
                onMouseOver={highlightButton}
                onMouseLeave={unHighlightButton}
              >
                View Market Data
              </button>

              {/* Space */}
              <div
                style={{
                  height: "40px",
                }}
              ></div>

              {/* Table */}
              <table
                className="borderTable"
                style={{
                  height: "112px",
                  width: "350px",
                  color: "white",
                }}
              >
                <tr>
                  <th>CUSIP</th>
                  <th>Bid</th>
                  <th>Ask</th>
                  <th>Timestamp</th>
                </tr>
                <tr>
                  <td>{showCUSIP.value}</td>
                  <td>{bid}</td>
                  <td>{ask}</td>
                  <td>{showClockState}</td>
                </tr>
              </table>
            </center>

            <div style={{ paddingLeft: 10, width: "30%" }}>
              <Section
                onClick={() => {
                  on("https://testlab.transficc.io/priceTaker");
                }}
                icon="price_check"
                header="Price Taker"
              />
            </div>
          </div>
        </div>

        <div align="center" height="80px" width="100%">
          <h2
            style={{
              color: "white",
              fontSize: 24,
              textAlign: "center",
              padding: 20,
            }}
          >
            <strong>200+ Available Venues</strong>
          </h2>
        </div>

        <table
          style={{
            height: "6000px",
            width: "100%",
            color: "white",
          }}
        >
          {/* Header */}

          <tr>
            <th>Venue</th>
            <th>Asset</th>
            <th>Adaptor</th>
            <th>Feature</th>
            <th>Status</th>
          </tr>

          {/* List */}

          <tr>
            <td>BGC CREDIT</td>
            <td>Corporate Bonds</td>
            <td>BGC CREDIT</td>
            <td>MARKET DATA TOP</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BGC CREDIT</td>
            <td>Corporate Bonds</td>
            <td>BGC CREDIT</td>
            <td>STATIC DATA</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BGC TRADER</td>
            <td>Government Bonds</td>
            <td>BGC TRADER</td>
            <td>MARKET DATA TOP</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BGC TRADER</td>
            <td>Government Bonds</td>
            <td>BGC TRADER</td>
            <td>STATIC DATA</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BITTREX (REFINITIV)</td>
            <td>Crypto</td>
            <td>REFINITIV BITTREX BRIDGE</td>
            <td>MARKET DATA</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BLOOMBERG BONDS</td>
            <td>Government Bonds</td>
            <td>BLOOMBERG BONDS PC</td>
            <td>PC</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BLOOMBERG BONDS</td>
            <td>Government Bonds</td>
            <td>BLOOMBERG BONDS TRADING</td>
            <td>RFQ</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BLOOMBERG BONDS</td>
            <td>Government Bonds</td>
            <td>BLOOMBERG BONDS TRADING</td>
            <td>CAT</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BLOOMBERG BONDS</td>
            <td>Government Bonds</td>
            <td>BLOOMBERG BONDS TRADING</td>
            <td>PROCESS TRADE</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BLOOMBERG BONDS</td>
            <td>Government Bonds</td>
            <td>BLOOMBERG BONDS TRADING</td>
            <td>BOLT</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>BLOOMBERG IRS</td>
            <td>IRS</td>
            <td>BLOOMBERG IRS TRADING</td>
            <td>RFQ</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BLOOMBERG IRS</td>
            <td>IRS</td>
            <td>BLOOMBERG IRS TRADING</td>
            <td>COMPRESSIONS</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>BLOOMBERG IRS</td>
            <td>IRS</td>
            <td>BLOOMBERG IRS TRADING</td>
            <td>PROCESS TRADE</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BLOOMBERG IRS</td>
            <td>IRS</td>
            <td>BLOOMBERG IRS TRADING</td>
            <td>CAT</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BLOOMBERG IRS</td>
            <td>IRS</td>
            <td>BLOOMBERG IRS PC</td>
            <td>PC</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BLOOMBERG IRS</td>
            <td>IRS</td>
            <td>BLOOMBERG IRS TRADING</td>
            <td>POST TRADE</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BLOOMBERG IRS</td>
            <td>IRS</td>
            <td>BLOOMBERG IRS TRADING</td>
            <td>MAC IMM ROLL</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>BLOOMBERG IRS</td>
            <td>IRS</td>
            <td>BLOOMBERG IRS TRADING</td>
            <td>RFM</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>BLOOMBERG IRS</td>
            <td>IRS</td>
            <td>BLOOMBERG IRS TRADING</td>
            <td>RFS</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BLOOMBERG IRS</td>
            <td>IRS</td>
            <td>BLOOMBERG IRS TRADING</td>
            <td>FUTURE SPREAD</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>BLOOMBERG VCON</td>
            <td></td>
            <td>BLOOMBERG VCON</td>
            <td>POST TRADE</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>BONDEVALUE BRIDGE (REFINITIV)</td>
            <td>Corporate Bonds</td>
            <td>BONDEVALUE REFINITIV BRIDGE</td>
            <td>MARKET DATA</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BROKERTEC EU</td>
            <td>Government Bonds</td>
            <td>BROKERTEC EU</td>
            <td>ORDERS</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BROKERTEC EU</td>
            <td>Government Bonds</td>
            <td>BROKERTEC EU</td>
            <td>QUOTES</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BROKERTEC EU LEGACY</td>
            <td>Government Bonds</td>
            <td>BROKERTEC EU LEGACY</td>
            <td>ORDERS</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BROKERTEC EU LEGACY</td>
            <td>Government Bonds</td>
            <td>BROKERTEC EU LEGACY</td>
            <td>QUOTES</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BROKERTEC QUOTE</td>
            <td>Repo</td>
            <td>BROKERTEC QUOTE POST TRADE</td>
            <td>POST TRADE</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>BROKERTEC STREAM BRIDGE</td>
            <td></td>
            <td>BROKERTEC STREAM BRIDGE</td>
            <td>PROTOCOL INTEGRATION</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>BTG PACTUAL (REFINITIV)</td>
            <td>FX</td>
            <td>BTG PACTUAL REFINITIV BRIDGE</td>
            <td>MARKET DATA</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>COINMETRIC (REFINITIV)</td>
            <td>Crypto</td>
            <td>COINMETRIC REFINITIV BRIDGE</td>
            <td>MARKET DATA</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>COMPASS (REFINITIV)</td>
            <td>Commodities</td>
            <td>COMPASS REFINITIV BRIDGE</td>
            <td>MARKET DATA</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>CS INDEX WEIGHTINGS (REFINITIV)</td>
            <td></td>
            <td>REFINITIV CS INDEX WEIGHTINGS BRIDGE</td>
            <td>MARKET DATA</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>CS INDICES (REFINITIV)</td>
            <td>Crypto</td>
            <td>CS INDICES REFINITIV BRIDGE</td>
            <td>MARKET DATA</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>DATATEC PERU</td>
            <td>Government Bonds</td>
            <td>DATATEC PERU</td>
            <td>MARKET DATA TOP</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>EUREX</td>
            <td>Government Bond Futures</td>
            <td>EUREX</td>
            <td>MARKET DATA UN AGG</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>EUWAX</td>
            <td>Corporate Bonds</td>
            <td>EUWAX TRADING</td>
            <td>RFQ</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>EUWAX</td>
            <td>Corporate Bonds</td>
            <td>EUWAX PC</td>
            <td>PC</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>FLIPSIDE CRYPTO (REFINITIV)</td>
            <td>Crypto</td>
            <td>FLIPSIDE REFINITIV BRIDGE</td>
            <td>MARKET DATA</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>GFI</td>
            <td>Corporate Bonds</td>
            <td>GFI</td>
            <td>MARKET DATA TOP</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>GFI</td>
            <td>Corporate Bonds</td>
            <td>GFI</td>
            <td>STATIC DATA</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>GODOT ET FILS (REFINITIV)</td>
            <td>Commodities</td>
            <td>REFINITIV GODOT ET FILS BRIDGE</td>
            <td>MARKET DATA</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>HDAT</td>
            <td>Government Bonds</td>
            <td>HDAT</td>
            <td>ORDERS</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>HDAT</td>
            <td>Government Bonds</td>
            <td>HDAT</td>
            <td>QUOTES</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>HDAT</td>
            <td>Government Bonds</td>
            <td>HDAT</td>
            <td>POST TRADE</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>ICE</td>
            <td>Government Bond Futures</td>
            <td>ICE</td>
            <td>MARKET DATA UN AGG</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>ISWAP</td>
            <td>IRS</td>
            <td>ISWAP MARKET DATA</td>
            <td>MARKET DATA</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>ISWAP</td>
            <td>IRS</td>
            <td>ISWAP TRADING</td>
            <td>ORDERS</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>MARKET AXESS EU</td>
            <td>Government Bonds</td>
            <td>MARKETAXESS EU TRADING</td>
            <td>RFQ</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>MARKET AXESS EU</td>
            <td>Government Bonds</td>
            <td>MARKETAXESS EU PC</td>
            <td>PC</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>MARKET AXESS EU</td>
            <td>Government Bonds</td>
            <td>MARKETAXESS EU POST TRADE</td>
            <td>POST TRADE</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>MARKET AXESS US</td>
            <td>Government Bonds</td>
            <td>MARKETAXESS US POST TRADE</td>
            <td>POST TRADE</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>MTS CASH</td>
            <td>Government Bonds</td>
            <td>MTS CASH</td>
            <td>ORDERS</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>MTS CASH</td>
            <td>Government Bonds</td>
            <td>MTS CASH</td>
            <td>QUOTES</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>MTS LIVE</td>
            <td>Government Bonds</td>
            <td>MTS LIVE</td>
            <td>MARKET DATA UN AGG</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>NASD NIGERIA (REFINITIV)</td>
            <td>Listed Equities</td>
            <td>NASD/NIGERIA REFINITIV BRIDGE</td>
            <td>MARKET DATA</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>NORGES BANK (REFINITIV)</td>
            <td>FX, Interest Rates</td>
            <td>NORGES BANK REFINITIV BRIDGE</td>
            <td>MARKET DATA</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>PHILIPPINES BANKERS ASSOC (REFINITIV)</td>
            <td>MM / FX</td>
            <td>PHP REFINITIV BRIDGE</td>
            <td>MARKET DATA</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>POLAND CENTRAL BANK (REFINITIV)</td>
            <td></td>
            <td>REFINITIV POLAND CENTRAL BANK BRIDGE</td>
            <td>MARKET DATA</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>REFINITIV</td>
            <td>Multi Asset</td>
            <td>REFINITIV MESSAGE API</td>
            <td>MARKET DATA</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>SENAF</td>
            <td>Government Bonds</td>
            <td>SENAF</td>
            <td>ORDERS</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>SENAF</td>
            <td>Government Bonds</td>
            <td>SENAF</td>
            <td>QUOTES</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>SENAF</td>
            <td>Government Bonds</td>
            <td>SENAF</td>
            <td>MARKET DATA AGG</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>SENAF</td>
            <td>Government Bonds</td>
            <td>SENAF</td>
            <td>POST TRADE</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TP ICAP</td>
            <td>Corporate Bonds</td>
            <td>TP ICAP</td>
            <td>MARKET DATA TOP</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TP ICAP</td>
            <td>Corporate Bonds</td>
            <td>TP ICAP</td>
            <td>STATIC DATA</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB CORI</td>
            <td>Corporate Bonds</td>
            <td>TRADEWEB CORI TRADING</td>
            <td>RFQ</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>TRADEWEB CORI</td>
            <td>Corporate Bonds</td>
            <td>TRADEWEB CORI TRADING</td>
            <td>PORTFOLIO</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>TRADEWEB CORI (REFINITIV)</td>
            <td>Corporate Bonds</td>
            <td>TRADEWEB CORI REFINITIV BRIDGE</td>
            <td>MARKET DATA</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU A2A AI EX</td>
            <td>Corporate Bonds</td>
            <td>TRADEWEB EU A2A AI EX</td>
            <td>RFQ</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU CORP</td>
            <td>Corporate Bonds</td>
            <td>TRADEWEB EU CORP TRADING</td>
            <td>RFQ</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU CORP</td>
            <td>Corporate Bonds</td>
            <td>TRADEWEB EU CORP TRADING</td>
            <td>ALL TO ALL</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU CORP</td>
            <td>Corporate Bonds</td>
            <td>TRADEWEB EU CORP TRADING</td>
            <td>PROCESS TRADE</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU CORP</td>
            <td>Corporate Bonds</td>
            <td>TRADEWEB EU CORP TRADING</td>
            <td>CAT</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU CORP</td>
            <td>Corporate Bonds</td>
            <td>TRADEWEB EU CORP TRADING</td>
            <td>NON CONTINGENT LIST</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU CORP</td>
            <td>Corporate Bonds</td>
            <td>TRADEWEB EU CORP TRADING</td>
            <td>PC</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU CORP</td>
            <td>Corporate Bonds</td>
            <td>TRADEWEB EU CORP TIERED PC</td>
            <td>AXES</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU CORP</td>
            <td>Corporate Bonds</td>
            <td>TRADEWEB EU CORP POST TRADE</td>
            <td>POST TRADE</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU CORP</td>
            <td>Corporate Bonds</td>
            <td>TRADEWEB EU CORP TRADING BUYSIDE</td>
            <td>ALL TO ALL AIEX</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU CORP</td>
            <td>Corporate Bonds</td>
            <td>TRADEWEB EU CORP TRADING</td>
            <td>PORTFOLIO</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU IRS</td>
            <td>IRS</td>
            <td>TRADEWEB EU IRS TRADING</td>
            <td>RFS</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU IRS</td>
            <td>IRS</td>
            <td>TRADEWEB EU IRS TRADING</td>
            <td>RFM</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU IRS</td>
            <td>IRS</td>
            <td>TRADEWEB EU IRS TRADING</td>
            <td>COMPRESSIONS</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU IRS</td>
            <td>IRS</td>
            <td>TRADEWEB EU IRS TRADING</td>
            <td>PROCESS TRADE</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU IRS</td>
            <td>IRS</td>
            <td>TRADEWEB EU IRS TRADING</td>
            <td>CAT</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU IRS</td>
            <td>IRS</td>
            <td>TRADEWEB EU IRS TRADING</td>
            <td>MAP</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU IRS</td>
            <td>IRS</td>
            <td>TRADEWEB EU IRS_TIERED PC</td>
            <td>PC</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU IRS</td>
            <td>IRS</td>
            <td>TRADEWEB EU IRS COMPOSITE PC</td>
            <td>COMPOSITE PC</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU IRS</td>
            <td>IRS</td>
            <td>TRADEWEB EU IRS POST TRADE</td>
            <td>POST TRADE</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU IRS</td>
            <td>IRS</td>
            <td>TRADEWEB EU IRS TRADING</td>
            <td>RFM FOR COMPRESSIONS</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU IRS</td>
            <td>IRS</td>
            <td>TRADEWEB EU IRS TRADING</td>
            <td>FUTURE SPREAD</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU IRS</td>
            <td>IRS</td>
            <td>TRADEWEB EU IRS TRADING</td>
            <td>ASSET SWAP</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EU IRS</td>
            <td>IRS</td>
            <td>TRADEWEB EU IRS TRADING</td>
            <td>MAC</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EUGV</td>
            <td>Government Bonds</td>
            <td>TRADEWEB EUGV TRADING</td>
            <td>RFQ</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EUGV</td>
            <td>Government Bonds</td>
            <td>TRADEWEB EUGV TRADING</td>
            <td>NON CONTINGENT LIST</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EUGV</td>
            <td>Government Bonds</td>
            <td>TRADEWEB EUGV TRADING</td>
            <td>AON</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EUGV</td>
            <td>Government Bonds</td>
            <td>TRADEWEB EUGV TRADING</td>
            <td>TAC</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EUGV</td>
            <td>Government Bonds</td>
            <td>TRADEWEB EUGV TRADING</td>
            <td>PROCESS TRADE</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EUGV</td>
            <td>Government Bonds</td>
            <td>TRADEWEB EUGV TRADING</td>
            <td>CAT</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EUGV</td>
            <td>Government Bonds</td>
            <td>TRADEWEB EUGV TIERED PC</td>
            <td>PC</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EUGV</td>
            <td>Government Bonds</td>
            <td>TRADEWEB EUGV TIERED PC</td>
            <td>AXES</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EUGV</td>
            <td>Government Bonds</td>
            <td>TRADEWEB EUGV COMPOSITE PC</td>
            <td>COMPOSITE PC</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EUGV</td>
            <td>Government Bonds</td>
            <td>TRADEWEB EUGV POST TRADE</td>
            <td>POST TRADE</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB EUGV</td>
            <td>Government Bonds</td>
            <td>TRADEWEB EUGV TRADING</td>
            <td>RFM</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB MBS SPEC POOLS</td>
            <td>MBS</td>
            <td>TRADEWEB MBS SPEC POOLS TRADING</td>
            <td>NON CONTINGENT LIST</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB MBS SPEC POOLS</td>
            <td>MBS</td>
            <td>TRADEWEB MBS SPEC POOLS TRADING</td>
            <td>AON</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB MBS SPEC POOLS</td>
            <td>MBS</td>
            <td>TRADEWEB MBS SPEC POOLS TRADING</td>
            <td>POST TRADE</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>TRADEWEB MBS SPEC POOLS</td>
            <td>MBS</td>
            <td>TRADEWEB MBS SPEC POOLS TRADING</td>
            <td>SPOT PRICING</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADEWEB REPO</td>
            <td>Repo</td>
            <td>TRADEWEB REPO POST TRADE</td>
            <td>POST TRADE</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADITION</td>
            <td>Corporate Bonds</td>
            <td>TRADITION</td>
            <td>MARKET DATA TOP</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TRADITION</td>
            <td>Corporate Bonds</td>
            <td>TRADITION</td>
            <td>STATIC DATA</td>
            <td style={{ backgroundColor: "#2ca444", color: "#FFFFFF" }}>
              LIVE
            </td>
          </tr>
          <tr>
            <td>TURKISH CENTRAL BANK (REFINITIV)</td>
            <td>FX, Interest Rates</td>
            <td>TURKISH CENTRAL BANK REFINITIV BRIDGE</td>
            <td>MARKET DATA</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>UBS BONDPORT (REFINITIV)</td>
            <td>Corporate Bonds</td>
            <td>UBS BONDPORT REFINITIV BRIDGE</td>
            <td>MARKET DATA</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
          <tr>
            <td>UKRAINE CENTRAL BANK (REFINITIV)</td>
            <td></td>
            <td>REFINITIV UKRAINE CENTRAL BANK BRIDGE</td>
            <td>MARKET DATA</td>
            <td style={{ backgroundColor: "#0c7484", color: "#FFFFFF" }}>
              PLANNING
            </td>
          </tr>
        </table>

        {/* Page that opens up when the Price Taker/Maker button is clicked */}
        <div
          class="overlay"
          style={{
            display: overlayLink,
          }}
        >
          <div
            class="overlay"
            style={{
              backgroundColor: "#2a2e39",
              height: "95%",
              width: "90%",
              left: "5%",
              top: "2%",
            }}
          >
            <div
              class="overlay"
              style={{
                backgroundColor: "white",
                height: "60%",
                width: "85%",
                left: "7%",
                top: "33%",
              }}
            >
              <iframe
                width="100%"
                height="100%"
                frameBorder="0"
                src={iframeLink}
              />

              <button
                style={{
                  position: "fixed",
                  top: "150px",
                  right: "120px",
                  height: "40px",
                  width: "80px",
                  backgroundColor: "#cc1c3c",
                  color: "white",
                  borderRadius: "5px",
                  border: "none",
                }}
                onClick={() => off()}
                onMouseOver={highlightXbutton}
                onMouseLeave={unHighlightXButton}
              >
                X
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTCTrade;
