import React, { useState, useEffect, useContext } from "react";
import { Text, StyleSheet } from "react-native";
import Time from "./Time";

export default () => {
    var currentdate = new Date();
    var min = currentdate.getMinutes();
    if (min < 10) {
    min = "0" + min;
    }
    var datetime =
    "As of " +
    (currentdate.getMonth() + 1) +
    "/" +
    currentdate.getDate() +
    "/" +
    currentdate.getFullYear() +
    " at ";

    return (
        <div>
            <div
            align="center"
            style={{
                background: "linear-gradient(45deg, #ac50ef, #7059fb 50%, #2ecff6)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "19px",
                fontFamily: "Helvetica",
            }}
            width="100%"
            >
            <Text
                style={{
                fontSize: "14px",
                verticalAlign: "center",
                textAlign: "center",
                color: "white",
                lineHeight: "19px",
                fontFamily: "Helvetica",
                }}
            >
                {datetime}
            </Text>
            <div style={{ color: "white", fontSize: "14px" }}>
                <Time
                style={{
                    fontSize: "15px",
                    verticalAlign: "center",
                    lineHeight: "19px",
                    margin: "0px",
                    padding: "0px",
                }}
                ></Time>
            </div>
        </div>
        </div>
    );
}