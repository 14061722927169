export function createData(label, description, lookML, type, SQL, key) {
    return { label, description, lookML, type, SQL, key};
}

export function updateTable(tableName) {
    let rows = [];
    for (let i = 0; i < tableName.length; i++) {
        rows.push(createData(tableName[i].Fl, tableName[i].D, tableName[i].LML, tableName[i].Type, tableName[i].SQL, i));
    }
    return rows;
}

export function sieve(rows, descript, type, searchTerm) {
    let filteredRows = [];
    let searchSuccess = false;
    for (let i = 0; i < rows.length; i++) {
        let id = 0;
        // document.write(rows[i]);
        let include = true;
        if(descript) {
            if(rows[i].description == "") {
                include = false
            }
        }
        if (type != "All") {
            if(!rows[i].type.includes(type)) {
                include = false
            }
        }
        if (searchTerm != null && !rows[i].label.toLowerCase().includes(searchTerm.toLowerCase())) {
            include = false;
        } else {
            searchSuccess = true;
        }

        if (include) {
            filteredRows.push(rows[i]);
        }
        
    }

    if (filteredRows.length == 0 || !searchSuccess) {
        filteredRows.push(createData("No search results."))
        
    } 
    return filteredRows
}