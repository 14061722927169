import { Text } from "react-native";
import React, { useState, useContext } from "react";
import { AppContext } from "../WebContextProvider.js";
import Section from "./Section.jsx";
import { Link, NavLink } from "react-router-dom";
import Bar from "./Bar";

export const bgColor = {
  backgroundColor: "#131722",
};

const headerStyle = {
  // backgroundColor: "#131722",
  backgroundColor: "#131315",
  borderBottom: "solid",
  borderBottomColor: "#6c6c6c",
  borderBottomWidth: "1px",
};

const boxStyle = {
  width: "400px",
  height: "300px",
  borderRadius: "20px",
  backgroundColor: "#1f2436",
  margin: "10px",
};

const miniBoxStyle = {
  width: "100%",
  height: "50px",
  backgroundColor: "#3a4346",
  textAlign: "center",
  // borderBottomLeftRadius: '10px',
  // borderBottomRightRadius: '10px',
};

const miniBoxStyle2 = {
  width: "100%",
  height: "50px",
  backgroundColor: "#3a4346",
  textAlign: "center",
  borderBottomLeftRadius: "20px",
  borderBottomRightRadius: "20px",
};

const miniButtonStyle = {
  color: "black",
  backgroundColor: "#c3c3c3",
  borderColor: "#c3c3c3",
  // width: '93',
  width: "116",
  height: "33",
  fontSize: "15",
  marginTop: "5",
};

export const mainTextColor = {
  color: "#9B9EA3",
};

export const label1 = {
  color: "#9B9EA3",
  fontSize: "30",
  textAlign: "center",
  lineHeight: "50px",
  paddingLeft: "13",
  paddingRight: "13",
};

const label2 = {
  color: "black",
  fontSize: "23",
  lineHeight: "50px",
  textDecoration: "none",
};

export const iconStyle = {
  color: "#9B9EA3",
};

const menuBackgroundColor = "#1f2436";

const buttonHighlightColor = "#2A2E39";

function highlightBoxButtonBackground(e) {
  let targ;
  let targName = e.target.className;
  if (e.target.className.substring(0, 3) === "img") {
    targ = e.target.parentNode.parentNode.parentNode;
  } else {
    switch (targName.substring(targName.length - 3, targName.length)) {
      case "box":
        targ = e.target;
        break;
      case "mb2":
        targ = e.target.parentNode.parentNode.parentNode;
        break;
      case "mb3":
        targ = e.target.parentNode.parentNode.parentNode;
        break;
      case "but":
        targ = e.target.parentNode.parentNode.parentNode.parentNode.parentNode;
        console.log(targ);
        break;
      default:
        if (targName.substring(0, 4) === "Flex") {
          targ = e.target.parentNode.parentNode;
        } else {
          targ = e.target.parentNode.parentNode.parentNode.parentNode;
        }
        break;
    }
  }

  targ.style.backgroundColor = buttonHighlightColor;
  targ.children[0].children[1].children[0].style.backgroundColor =
    buttonHighlightColor;
  targ.children[0].children[1].children[1].style.backgroundColor =
    buttonHighlightColor;
}

function unhighlightBoxButtonBackground(e) {
  let targ;
  let targName = e.target.className;
  if (e.target.className.substring(0, 3) === "img") {
    targ = e.target.parentNode.parentNode.parentNode;
  } else {
    switch (targName.substring(targName.length - 3, targName.length)) {
      case "box":
        targ = e.target;
        break;
      case "mb2":
        targ = e.target.parentNode.parentNode.parentNode;
        break;
      case "mb3":
        targ = e.target.parentNode.parentNode.parentNode;
        break;
      case "but":
        targ = e.target.parentNode.parentNode.parentNode.parentNode.parentNode;
        console.log(targ);
        break;
      default:
        if (targName.substring(0, 4) === "Flex") {
          targ = e.target.parentNode.parentNode;
        } else {
          targ = e.target.parentNode.parentNode.parentNode.parentNode;
        }
        break;
    }
  }

  targ.style.backgroundColor = menuBackgroundColor;
  targ.children[0].children[1].children[0].style.backgroundColor = "#3a4346";
  targ.children[0].children[1].children[1].style.backgroundColor = "#3a4346";
}

function underlineText(e) {
  e.target.style.textDecoration = "none";
  e.target.style.textDecorationColor = "black";
}

function removeUnderline(e) {
  e.target.style.textDecoration = "none";
}

//Function to make cursor a hand on mouse over
function cursorHand(e) {
  e.target.style.cursor = "pointer";
}

// class Dashboards extends React.Component {
//   render() {
const Trade = () => {
  const { setVideo, setEmbedID } = useContext(AppContext);
  let initialSubheaderFontSize;
  if (window.innerWidth > 568) {
    initialSubheaderFontSize = 37;
  } else {
    initialSubheaderFontSize = 31;
  }

  const [subheaderFontSize, setSubheaderFontSize] = useState(
    initialSubheaderFontSize
  );
  return (
    <div>
      <div align="center" width="100%" height="80px">
        <Text
          backgroundColor="#131722"
          style={{
            lineHeight: "80px",
            fontSize: 45,
            color: "white",
            marginTop: 10,
          }}
        >
          Trades
        </Text>
      </div>

      <Bar />
      <br />

      {/* Trades Buttons */}
      <div className="container-fluid" style={{ minHeight: "100vh" }}>
        <div class="card-deck-wraper">
          <div class="card-deck d-flex flex-type justify-content-center">
            <div style={{ width: "80%", paddingBottom: 20 }}>
              <div className="sectionGrid">
                <div></div>
                <Section
                  to="/etrade"
                  onClickTutorial={() => {
                    setVideo("Etrade");
                    setEmbedID("ztwltLjmOsk");
                  }}
                  icon="swap_horiz"
                  header="eTrade"
                />
                <Section
                  to="/otc-trade"
                  onClickTutorial={() => {
                    setVideo("Bond Intelligence Intro");
                    setEmbedID("NJlUiWPknb4");
                  }}
                  icon="dashboard"
                  header="OTC Trade"
                />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trade;
