import React, { useState, useContext, useEffect, useRef } from "react";
import { AppContext } from "./WebContextProvider.js";
import Logo from "./components/openexamainlogo.png";
import { useScript } from "./hooks/useScript";
// import { useNavigate } from "react-router-dom"
import jwt_deocde from "jwt-decode";
import RenderedWebpage from "./RenderedWebpage.js";

/*
This file consists of two parts: 
_ Part 1: Render the login page
_ Part 2: Render the Bond Intelligence website after successfully logging in
*/

// export const AppContext = createContext();

function App() {
  const {
    unameHome,
    setUnameHome,
    isFormSubmitted,
    setIsFormSubmitted,
    identity,
    setIdentity,
  } = useContext(AppContext);
  // Start Google Login
  const googlebuttonref = useRef();
  // const [user, setuser] = useState(false);
  // const [given_name, setGiven_name] = useState();
  const onGoogleSignIn = (user) => {
    let userCred = user.credential;
    console.log(userCred);
    // let u = user.name;
    // console.log(user.name);
    // console.log(unameHome);
    // let setUserName(userName);
    let payload = jwt_deocde(userCred);
    setUnameHome(payload.name);
    // setuser(payload);
    setIdentity(true);
  };
  //   useScript("https://accounts.google.com/gsi/client", () => {
  //     window.google.accounts.id.initialize({
  //       client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID, // here's the Google ID
  //       // client_id: "874086611566-hmi95656g1e79la2mh8npmshea75bijd.apps.googleusercontent.com", // here's the Google ID
  //       callback: onGoogleSignIn,
  //       auto_select: true,
  //     });

  //     window.google.accounts.id.renderButton(googlebuttonref.current, {
  //       size: "large",
  //     });
  //   });
  // End Google Login

  /**
   * Using useEffect and sessionStorage to block the context to reset
   * after reloading the page by clicking the logo.
   * These two functions help prevent from logging out when a user clicks
   * the logo on the left side of the header bar.
   */
  if (sessionStorage.getItem("isFormSubmitted") === "true") {
    setIsFormSubmitted(sessionStorage.getItem("isFormSubmitted"));
    setUnameHome(sessionStorage.getItem("unameHome"));
  }
  useEffect(() => {
    sessionStorage.setItem("isFormSubmitted", isFormSubmitted);
    sessionStorage.setItem("unameHome", unameHome);
  }, [isFormSubmitted, unameHome]);
  // End of useEffect

  // Part 1: Render the login page
  // States
  const [errorMess, setErrorMess] = useState({});
  // const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  // const [unameHome, setUnameHome] = useState();

  // User Login info for testing purposes
  const info = [
    {
      username: "Bob",
      password: "Openexa123!",
    },
    {
      username: "Luke",
      password: "Openexa123!",
    },
    {
      username: "Rachael",
      password: "Openexa123!",
    },
    {
      username: "John",
      password: "Openexa123!",
    },
    {
      username: "Barnet",
      password: "Openexa123!",
    },
    {
      username: "Tim",
      password: "Openexa123!",
    },
    {
      username: "Mike",
      password: "Openexa123!",
    },
  ];

  // Error messages
  const errors = {
    usrname: "invalid username",
    pass: "invalid password",
  };

  // Method to handle when the submit button is clicked
  const handleSubmit = (event) => {
    //Prevent the page to submit when reload
    event.preventDefault();
    // event.stopImmediatePropagation();

    var { usrname, pass } = document.forms[0];
    setUnameHome(document.forms[0]["usrname"].value);
    // sessionStorage.setItem('unameHome', unameHome.toString());

    // Find the user login info
    const userInfo = info.find((user) => user.username === usrname.value);

    // Compare input values with data in info database
    // Username found in info database
    if (userInfo) {
      if (userInfo.password !== pass.value) {
        // Password not found in info database
        setErrorMess({ name: "pass", mess: errors.pass });
      } else {
        setIsFormSubmitted("true");
        // sessionStorage.setItem("isFormSubmitted", "true");
      }
    } else {
      // Username not found in info database
      setErrorMess({ name: "usrname", mess: errors.usrname });
    }
  };

  // Method to render the error messages
  const renderErrorMess = (name) =>
    name === errorMess.name && (
      <div className="error-login">{errorMess.mess}</div>
    );

  // Method to render the login page
  const renderLoginPage = (
    <div className="login">
      <div style={{ padding: 20, backgroundColor: "rgba(32, 34, 50, 0.5)" }}>
        {/* Logo */}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="logo">
            <img
              className="openexa-logo"
              style={{
                width: "150px",
                height: "auto",
                marginLeft: "5px",
                marginRight: "5px",
                paddingBottom: "20px",
              }}
              src={Logo}
              alt="Openexa Logo"
            />
          </div>
        </div>
        <div className="title-login" style={{ textAlign: "center" }}>
          Log In
        </div>
        <div className="welcome-login" style={{ textAlign: "center" }}>
          Welcome to OpenEXA.
        </div>
        <form onSubmit={handleSubmit}>
          <div className="input-container-login">
            <label className="field-name-login">Username</label>
            <input
              type="text"
              name="usrname"
              style={{
                padding: "0 5px",
                color: "white",
                backgroundColor: "#3B3B3B",
              }}
              required
            />
            {renderErrorMess("usrname")}
          </div>
          <div className="input-container-login">
            <label className="field-name-login">Password</label>
            <input
              type="password"
              name="pass"
              style={{
                padding: "0 5px",
                color: "white",
                backgroundColor: "#3B3B3B",
              }}
              required
            />
            {renderErrorMess("pass")}
          </div>
          <div className="button-container-login">
            <input type="submit" value="LOG IN" />
          </div>
          <br />
          {!identity && (
            <div className="button-container-login" ref={googlebuttonref}></div>
          )}
          {identity && setIsFormSubmitted("true")}
          {/* {user && (
          <div>
            
          <h1>{user.name}</h1>
          <img src={user.picture} alt="profile" />
          <p>{user.email}</p>
          <button
              onClick={() => {
                setuser(false);
              }}
          >
            Logout
            </button>
            <Link
              to={{
                pathname: "/",
                data: [user.name, user.picture] // your data array of objects
              }}
            ></Link>
            <RenderedWebpage /> */}
          {/* <Routes>
              <Route exact path="/" element={<RenderedWebpage />} />
              <Route  exact to="https://google.com" />
            </Routes> */}
          {/* <Navigate to="/" element={<RenderedWebpage />} /> */}
          {/* </div>
        )} */}
          <div className="foot-login">
            <div className="trust-message">
              <input
                type="checkbox"
                id="trust-login"
                name="trust-login"
                value="trustComp"
              />
              <label for="trust-login" className="label-trust">
                Stay logged in. This is a trusted computer.
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
  // End of Part 1

  // Part 2: Render the Bond Intelligence website after successfully logging in
  // const renderWebsite = (
  //   <RenderedWebpage />
  // );
  // End of Part 2

  const renderWebsite = <RenderedWebpage />;

  // Rendering
  if (isFormSubmitted === "true") {
    return renderWebsite;
  } else if (isFormSubmitted === "false") {
    return renderLoginPage;
  }
}

export default App;
