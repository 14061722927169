import React, { useEffect } from 'react';
import { useLocation } from 'react-router'; 

function TopOfPage(props) {
  const position = useLocation(); 
  useEffect(() => {
      window.scrollTo(0, 0);
    }, [position]);
  
  return <>{props.children}</>
}

export default TopOfPage; 