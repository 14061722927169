import React, { useState, useContext, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { Text } from "react-native";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { AppContext } from "../WebContextProvider.js";


const mainTextColor = {
    color: "#9B9EA3",
  };


  function underlineText(e) {
    e.target.style.textDecoration = 'none'; 
    e.target.style.textDecorationColor = 'black'; 
  }

function removeUnderline(e) {
    e.target.style.textDecoration = 'none'
  }

function Section(props) {
    const { setVideo, setEmbedID, setStudioLink } = useContext(AppContext);
  let initialSubheaderFontSize;
  if (window.innerWidth > 568) {
    initialSubheaderFontSize = 37;
  } else {
    initialSubheaderFontSize = 31;
  }

  const [subheaderFontSize, setSubheaderFontSize] = useState(
    initialSubheaderFontSize
  );
  return (
    <div style={{flexWrap: "wrap", height: "100%"}} className="">
      <NavLink className="link explore" exact to={props.to} onClick={props.onClick} state={props.state ?? ""} style={{textDecoration: 'none', background: "transparent", height: "100%"}}>   
            <div class="sectionCard card text-center">
                <div class="card-body">
                  <span class="material-icons" style={{ fontSize: 120, marginBottom: 20  }}>{props.icon}</span>
                  <div class="launchSurround"><div class="launch"><p>Launch App</p><i class="material-icons">launch</i></div></div>
                </div>
                <div class="card-footer">
                    <Text
                      style={{fontSize: 22, marginBottom: 5, color: "#9B9EA3", textAlign: "left", width: "100%"}}
                    >
                        {props.header}
                    </Text>
                    <div style={{width: "100%", display: "flex", alignContent: "left"}}>
                      <NavLink
                          class="link tutorial" 
                          exact to="/tutorials"
                          state={props.state ?? ""}
                          onClick={props.onClickTutorial}
                          onMouseOver={underlineText} 
                          onMouseLeave={removeUnderline} 
                          style={{ marginBottom: '5px'}}
                      >
                          <div class="card tutorial" style={{borderRadius: 500}}>
                              <span class="material-icons md-12" style={{color: "white"}} >school</span>
                              <Text
                              style={{fontSize: 15, color: 'white', fontWeight: '500'}}
                              color={mainTextColor}
                              fontSize={subheaderFontSize}
                              onMouseOver={underlineText} 
                              onMouseLeave={removeUnderline}
                              >
                                How to Use
                              </Text>
                          </div>
                      </NavLink>
                    </div>
                </div>
            </div>
      </NavLink>
    </div>
  );
}

export default Section;