import { Text } from "react-native";
import React, { useState, useContext } from "react";
import { AppContext } from "../WebContextProvider.js";

const bgColor = {
  backgroundColor: "#131315",
};

const headerStyle = {
  // backgroundColor: "#131722",
  backgroundColor: "#131315",
  borderBottom: "solid",
  borderBottomColor: "#6c6c6c",
  borderBottomWidth: "1px",
};

export const mainTextColor = {
  color: "#9B9EA3",
};

const ETrade = () => {
  let initialSubheaderFontSize;
  if (window.innerWidth > 568) {
    initialSubheaderFontSize = 37;
  } else {
    initialSubheaderFontSize = 31;
  }

  const [subheaderFontSize, setSubheaderFontSize] = useState();
  const [quantity, setQuantity] = useState("1");
  const [color, setColor] = useState("green");
  const [buySell, setBuySell] = useState("");

  const [financeName, setFinanceName] = useState("");
  const [sbPrice, setSbPrice] = useState("");

  // States for crypto info
  // const [cryptoName, setCryptoName] = useState("");
  const [ccPrice, setCcPrice] = useState("");
  const [chPrice, setChPrice] = useState("");
  const [clPrice, setClPrice] = useState("");
  const [coPrice, setCoPrice] = useState("");
  const [csPrice, setCsPrice] = useState("");
  const [timeStamp, setTimeStamp] = useState("");
  const [volData, setVolData] = useState("");

  // States for stock info
  // const [stockName, setStockName] = useState("");
  const [oPrice, setOPrice] = useState("");
  const [hPrice, setHPrice] = useState("");
  const [lPrice, setLPrice] = useState("");
  const [cPrice, setCPrice] = useState("");
  const [dPrice, setDPrice] = useState("");
  const [dpPrice, setDpPrice] = useState("");
  const [pcPrice, setPcPrice] = useState("");

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setQuantity(document.getElementById("quantity").value);
    }
  };

  let content;
  let isCrypto = false;

  const handleKeyPress2 = (e) => {
    if (e.key === "Enter") {
      content = document.getElementById("nameStock").value.toUpperCase();
      setFinanceName(content);
      pullData();
    }
  };

  const handleKeyPress3 = (e) => {
    if (e.key === "Enter") {
      isCrypto = true;
      content = document.getElementById("nameCrypto").value.toUpperCase();
      setFinanceName(content);
      pullData(isCrypto);
    }
  };

  // Pull data from Finnhub API to React App
  // Note: "Refused to set unsafe header User-Agent" can be ignored
  const pullData = (isCrypto) => {
    let symbol = content;
    let initialTime = Math.floor(Date.now() / 1000) - 60;
    let currentTime = Math.floor(Date.now() / 1000);
    const finnhub = require("finnhub");

    const api_key = finnhub.ApiClient.instance.authentications["api_key"];
    api_key.apiKey = "ccr0na2ad3ifm8tq8q3gccr0na2ad3ifm8tq8q40";
    const finnhubClient = new finnhub.DefaultApi();

    if (isCrypto == true) {
      finnhubClient.cryptoCandles(
        symbol,
        "1",
        initialTime,
        currentTime,
        (error, data, response) => {
          if (data.c !== void 0) {
            setCcPrice(data.c[0]);
            setSbPrice(data.c[0]);
            setChPrice(data.h[0]);
            setClPrice(data.l[0]);
            setCoPrice(data.o[0]);
            setCsPrice(data.s);
            setTimeStamp(data.t[0]);
            setVolData(data.v[0]);
          }
        }
      );
      console.log("API call for crypto was successful");
    } else {
      finnhubClient.quote(symbol, (error, data, response) => {
        if (typeof data !== "undefined") {
          setOPrice(data.o);
          setHPrice(data.h);
          setLPrice(data.l);
          setCPrice(data.c);
          setSbPrice(data.c);
          setDPrice(data.d);
          setDpPrice(data.dp);
          setPcPrice(data.pc);
        }
      });
      console.log("API call for stock was successful");
    }
  };

  return (
    <>
      <div align="center" width="100%" height="80px">
        <Text
          backgroundColor="#131722"
          style={{
            lineHeight: "80px",
            fontSize: 45,
            color: "white",
            marginTop: 10,
          }}
        >
          eTrade
        </Text>
      </div>
      {/* <div
          align="center"
          width="100%"
          height="80px"
          style={{
            backgroundColor: "#131315",
            borderStyle: "solid",
            borderWidth: "0.01em 0px 0.01em 0px",
            borderColor: "#6c6c6c",
          }}
        ></div> */}
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <div className="etrade-view" height="1200px">
          <iframe
            className="etrade-embed"
            width="75%"
            frameBorder="0"
            height="1200px"
            src="https://www.tradingview.com/widgetembed/?frameElementId=tradingview_4373b&symbol=BINANCE%3ABTCUSDT&interval=D&hidesidetoolbar=1&symboledit=1&saveimage=1&toolbarbg=f1f3f6&studies=%5B%5D&theme=dark&style=1&timezone=Etc%2FUTC&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en&utm_source=www.tradingview.com&utm_medium=widget_new&utm_campaign=chart&utm_term=BINANCE%3ABTCUSDT"
          />
          <div className="buy-sell">
            <label id="cryptoName">
              <Text
                style={{
                  fontSize: 16,
                  color: "#C1C6CC",
                  display: "block",
                  lineHeight: "30px",
                }}
              >
                Crypto:
              </Text>
              <input
                type="text"
                id="nameCrypto"
                name="nameCrypto"
                placeholder="Type source:crypto"
                onKeyPress={handleKeyPress3}
              />
            </label>
            <div className="cryptoInfo">
              <Text
                style={{
                  fontSize: 16,
                  color: "#C1C6CC",
                  display: "block",
                  lineHeight: "30px",
                }}
              >
                Open Price: {coPrice}
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  color: "#C1C6CC",
                  display: "block",
                  lineHeight: "30px",
                }}
              >
                High Price: {chPrice}
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  color: "#C1C6CC",
                  display: "block",
                  lineHeight: "30px",
                }}
              >
                Low Price: {clPrice}
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  color: "#C1C6CC",
                  display: "block",
                  lineHeight: "30px",
                }}
              >
                Close Price: {ccPrice}
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  color: "#C1C6CC",
                  display: "block",
                  lineHeight: "30px",
                }}
              >
                Response Status: {csPrice}
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  color: "#C1C6CC",
                  display: "block",
                  lineHeight: "30px",
                }}
              >
                Time Stamp: {timeStamp}
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  color: "#C1C6CC",
                  display: "block",
                  lineHeight: "30px",
                }}
              >
                Volumn Data: {volData}
              </Text>
            </div>

            <label id="stockName">
              <Text
                style={{
                  fontSize: 16,
                  color: "#C1C6CC",
                  display: "block",
                  lineHeight: "30px",
                }}
              >
                Stock:
              </Text>
              <input
                type="text"
                id="nameStock"
                name="nameStock"
                placeholder="Type stock symbol"
                onKeyPress={handleKeyPress2}
              />
            </label>
            <div className="info">
              <Text
                style={{
                  fontSize: 16,
                  color: "#C1C6CC",
                  display: "block",
                  lineHeight: "30px",
                }}
              >
                Open Price: {oPrice}
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  color: "#C1C6CC",
                  display: "block",
                  lineHeight: "30px",
                }}
              >
                High Price: {hPrice}
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  color: "#C1C6CC",
                  display: "block",
                  lineHeight: "30px",
                }}
              >
                Low Price: {lPrice}
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  color: "#C1C6CC",
                  display: "block",
                  lineHeight: "30px",
                }}
              >
                Current Price: {cPrice}
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  color: "#C1C6CC",
                  display: "block",
                  lineHeight: "30px",
                }}
              >
                Change: {dPrice}
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  color: "#C1C6CC",
                  display: "block",
                  lineHeight: "30px",
                }}
              >
                Percent Change: {dpPrice}
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  color: "#C1C6CC",
                  display: "block",
                  lineHeight: "30px",
                }}
              >
                Previous Close Price: {pcPrice}
              </Text>
            </div>
            <button
              className="button-sell"
              onClick={() => {
                setBuySell("SELL");
                document.getElementsByClassName(
                  "button-buysell"
                )[0].style.backgroundColor = "#F23645";
              }}
            >
              SELL
              <Text
                style={{
                  fontSize: 18,
                  color: "#C1C6CC",
                  display: "block",
                  lineHeight: "20px",
                }}
              >
                {sbPrice}
              </Text>
            </button>
            <button
              className="button-buy"
              onClick={() => {
                setBuySell("BUY");
                document.getElementsByClassName(
                  "button-buysell"
                )[0].style.backgroundColor = "#2962FF";
              }}
            >
              BUY
              <Text
                style={{
                  fontSize: 18,
                  color: "#C1C6CC",
                  display: "block",
                  lineHeight: "20px",
                }}
              >
                {sbPrice}
              </Text>
            </button>
          </div>
          <label id="units">
            <Text
              style={{
                fontSize: 16,
                color: "#C1C6CC",
                display: "block",
                lineHeight: "30px",
              }}
            >
              Units:
            </Text>
            <input
              type="number"
              id="quantity"
              name="quantity"
              placeholder="1"
              onKeyPress={handleKeyPress}
            />
          </label>
          <button
            className="button-buysell"
            style={{
              backgroundColor: "#F23645",
            }}
          >
            <Text
              style={{
                fontSize: 18,
                color: "#C1C6CC",
                display: "block",
                lineHeight: "20px",
              }}
            >
              {buySell} {quantity} {financeName}
            </Text>
          </button>
        </div>
      </div>
    </>
  );
};

export default ETrade;
