import { Text } from "react-native";
import React, { useState } from "react";

const bgColor = {
  backgroundColor: "#131315",
};

const headerStyle = {
  // backgroundColor: "#131722",
  backgroundColor: "#131315",
  borderBottom: "solid",
  borderBottomColor: "#6c6c6c",
  borderBottomWidth: "1px",
};

export const mainTextColor = {
  color: "#9B9EA3",
};

const Crypto = () => {
  let initialSubheaderFontSize;
  if (window.innerWidth > 568) {
    initialSubheaderFontSize = 37;
  } else {
    initialSubheaderFontSize = 31;
  }

  const [subheaderFontSize, setSubheaderFontSize] = useState(
    initialSubheaderFontSize
  );
  return (
    <>
      <div
        align="center"
        width="100%"
        height="80px"
        style={{ marginBottom: 10, marginTop: 5 }}
      >
        <Text
          backgroundColor="#131722"
          style={{
            lineHeight: "80px",
            fontSize: 45,
            color: "white",
            marginTop: 10,
          }}
        >
          Crypto Screener
        </Text>
      </div>

      {/* <div
          align="center"
          width="100%"
          height="80px"
          style={{
            backgroundColor: "#131315",
            borderStyle: "solid",
            borderWidth: "0.01em 0px 0.01em 0px",
            borderColor: "#6c6c6c",
          }}
        ></div> */}
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <div height="1200px" style={bgColor}>
          <iframe
            width="100%"
            frameBorder="0"
            height="1200px"
            src="https://www.tradingview.com/embed-widget/crypto-mkt-screener/?locale=en#%20%20%7B%0A%20%20%22width%22%3A%20%22100%25%22%2C%0A%20%20%22height%22%3A%20%22100%25%22%2C%0A%20%20%22defaultColumn%22%3A%20%22overview%22%2C%0A%20%20%22screener_type%22%3A%20%22crypto_mkt%22%2C%0A%20%20%22displayCurrency%22%3A%20%22USD%22%2C%0A%20%20%22colorTheme%22%3A%20%22dark%22%2C%0A%20%20%22locale%22%3A%20%22en%22%0A%7D"
          />
        </div>
      </div>
    </>
  );
};

export default Crypto;
