import React from "react";
import { NavLink } from "react-router-dom";
import Error from "./404-error.jpg" ;
import { Text } from "react-native";

function underlineText(e) {
    e.target.style.textDecoration = 'underline'; 
    e.target.style.textDecorationColor = 'rgb(51, 153, 255)'; 
  }

function removeUnderline(e) {
    e.target.style.textDecoration = 'none'
  }


function FourOFour() {
    return (
        <div className="fourOfour">
            <center className="fourOfourText">
                <Text
                        backgroundColor="#131722"
                        style={{
                            fontSize: 150, 
                            color: 'gray'
                        }}
                    >
                        404
                </Text>
            </center>
            <center>
                <p
                    style={{
                        color: 'gray', 
                        fontSize: 18, 
                    }}
                >
                    This requested URL was not found on this server. 
                </p>
            </center>
            <center>
                <NavLink 
                    exact to="/" 
                    style={{textDecoration: 'none'}}
                    onMouseOver={underlineText} 
                    onMouseLeave={removeUnderline} 
                >
                    <Text
                        backgroundColor="#131722"
                        style={{
                            lineHeight: "20px", 
                            fontSize: 18, 
                            color: 'rgb(51, 153, 255)', 
                        }}
                    >
                        Return Home
                    </Text>
                </NavLink>
            </center>
        </div>    
    ); 
}

export default FourOFour;