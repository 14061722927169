import { Text } from "react-native";
import React, { useState } from "react";

const bgColor = {
  backgroundColor: "#131315",
};

const headerStyle = {
  // backgroundColor: "#131722",
  backgroundColor: "#131315",
  borderBottom: "solid",
  borderBottomColor: "#6c6c6c",
  borderBottomWidth: "1px",
};

export const mainTextColor = {
  color: "#9B9EA3",
};

const Bond = () => {
  let initialSubheaderFontSize;
  if (window.innerWidth > 568) {
    initialSubheaderFontSize = 37;
  } else {
    initialSubheaderFontSize = 31;
  }

  const [subheaderFontSize, setSubheaderFontSize] = useState(
    initialSubheaderFontSize
  );
  return (
    <>
      <div
        align="center"
        width="100%"
        height="80px"
        style={{ marginBottom: 10, marginTop: 5 }}
      >
        <Text
          backgroundColor="#131722"
          style={{
            lineHeight: "80px",
            fontSize: 45,
            color: "white",
            marginTop: 10,
          }}
        >
          AUT Screener
        </Text>
      </div>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <div height="1200px" style={bgColor}>
          <iframe
            width="100%"
            frameBorder="0"
            height="1200px"
            src="https://screener-wmn5n7rc5q-uc.a.run.app/"
          />
        </div>
      </div>
    </>
  );
};

export default Bond;
