import React, { useState, createContext } from "react"; 


export const AppContext = createContext();

const WebContextProvider = (props) => {
    const [isFormSubmitted, setIsFormSubmitted] = useState("false");
    const [unameHome, setUnameHome] = useState(); 
    const [video, setVideo] = useState('');
    const [embedID, setEmbedID] = useState('NJlUiWPknb4');
    const [identity, setIdentity] = useState(false);
    const [studioLink, setStudioLink] = useState(""); 
    return (
        <AppContext.Provider value={{
            unameHome, 
            setUnameHome, 
            isFormSubmitted, 
            setIsFormSubmitted, 
            video, 
            setVideo, 
            embedID, 
            setEmbedID,
            identity,
            setIdentity,
            studioLink, 
            setStudioLink
        }}>
            {props.children}
        </AppContext.Provider>
    )
}

export default WebContextProvider;