import { Text } from "react-native";
import React, { useState } from "react";

const bgColor = {
  backgroundColor: "#131315",
};

const headerStyle = {
  // backgroundColor: "#131722",
  backgroundColor: "#131315",
  borderBottom: "solid",
  borderBottomColor: "#6c6c6c",
  borderBottomWidth: "1px",
};

export const mainTextColor = {
  color: "#9B9EA3",
};

const AUTTokens = () => {
  let initialSubheaderFontSize;
  if (window.innerWidth > 568) {
    initialSubheaderFontSize = 37;
  } else {
    initialSubheaderFontSize = 31;
  }

  const [subheaderFontSize, setSubheaderFontSize] = useState(
    initialSubheaderFontSize
  );
  return (
    <>
       <div
        align="center"
        width="100%"
        height="80px"
        style={{
          backgroundColor: "#131315",
          borderStyle: "solid",
          borderWidth: "0px 0px 0.01em 0px",
          borderColor: "#6c6c6c",
        }}
      >
        <Text
          backgroundColor="#131722"
          style={{ lineHeight: "80px", fontSize: 45, color: "darkgray" }}
          color={mainTextColor}
          fontSize={subheaderFontSize}
        >
          AUT Tokens
        </Text>
      </div>
      <div height="1200px" style={bgColor}>
        <iframe
          width="100%"
          frameBorder="0"
          height="1200px"
          src="https://remix.ethereum.org/#optimize=false&runs=200&evmVersion=null&version=soljson-v0.8.7+commit.e28d00a7.js"
        />
      </div>
    </>
  );
};

export default AUTTokens;
